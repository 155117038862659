<template>
  <div class="bg-darkcard z-50">
    <div class="hidden lg:flex justify-between">
      <div class="px-5 flex justify-start space-x-12 py-3 w-3/5">
        <p
          v-for="(nav, i) in navBar"
          :key="i"
          :class="{
            'text-text': active == nav.to,
          }"
          class="text-gray-300 b text-sm cursor-pointer"
          @click="routerDotPush(nav.to)"
        >
          {{ nav.label }}
        </p>
        <div class="flex space-x-2 text-yellow-500 text-sm">
          <p class="text-green-500">Game Id:{{ currentGameId }}</p>
          <p>{{ gameStatus }}</p>

          <vue-countdown-timer
            class="text-redo"
            :start-time="startingTime"
            :end-time="endingTime"
            :interval="1000"
            :start-label="''"
            :end-label="''"
            label-position=""
            :end-text="'00:00'"
            :day-txt="''"
            :hour-txt="''"
            :minutes-txt="':'"
            :seconds-txt="''"
          >
          </vue-countdown-timer>
        </div>
      </div>
      <div class="flex justify-evenly space-x-6 my-2 mx-5">
        <div class="flex space-x-8">
          <p
            v-if="user.agentName"
            class="text-gray-300 b text-sm cursor-pointer"
          >
            {{ returnUserName }}
          </p>
          <div class="flex justify-center text-xs text-gray-300">
            <p class="cursor-pointer bg-text rounded-md p-1" @click="logout()">
              Logout
            </p>
          </div>
          <!-- <div class="flex justify-center">
            <img class="h-6" src="../assets/Login.svg" alt="" />
          </div> -->
        </div>
      </div>
    </div>
    <div class="text-white lg:hidden p-2 flex justify-between">
      <div class="flex space-x-2 text-base">
        <img
          @click="isOpen = !isOpen"
          src="../assets/humMenu.svg"
          alt=""
          srcset=""
        />
        <p class="text-lg">KenoPro</p>
      </div>
      <div class="flex space-x-2 text-yellow-500 text-xs">
        <p class="text-green-500">Game Id:{{ currentGameId }}</p>
        <p>{{ gameStatus }}</p>

        <vue-countdown-timer
          class="text-redo"
          :start-time="startingTime"
          :end-time="endingTime"
          :interval="1000"
          :start-label="''"
          :end-label="''"
          label-position=""
          :end-text="'00:00'"
          :day-txt="''"
          :hour-txt="''"
          :minutes-txt="':'"
          :seconds-txt="''"
        >
        </vue-countdown-timer>
      </div>
      <transition name="fade" appear="">
        <div
          class="modal-overlay"
          v-if="isOpen"
          @click="isOpen = !isOpen"
        ></div>
      </transition>
      <div
        class="modal fixed p-4 top-0 left-0 w-88 h-full bg-darkcard text-white"
        :class="{ hidden: !isOpen }"
        style="z-index: 99; width: 230px"
      >
        <div class="flex space-x-2 w-full m-5">
          <img
            @click="isOpen = !isOpen"
            src="../../public/logo.svg"
            alt=""
            class="w-8 h-8"
            srcset=""
          />
          <p>KenoPro</p>
        </div>

        <div class="bg-darkbg w-full">
          <p
            v-for="(nav, i) in navBar"
            :key="i"
            :class="{
              'text-text': active == nav.to,
            }"
            class="text-gray-300 b text-base p-3 cursor-pointer"
            @click="routerDotPush(nav.to)"
          >
            {{ nav.label }}
          </p>
        </div>
        <div class="flex justify-center text-base w-full text-gray-300">
          <p
            class="cursor-pointer bg-text w-full mt-3 rounded-md p-3"
            @click="logout()"
          >
            Logout
          </p>
        </div>
      </div>
      <!-- <div class="dropdown hover:block relative inline-block my-auto mx-4">
        <span class="bg-blue-800"
          ><img src="../assets/humMenu.svg" alt="" srcset=""
        /></span>
        <div
          class="dropdown-content bg-black hover:block hover:text-left hover:right-0 z-10 :hover hidden shadow-md px-3 py-4 absolute min-w-40 w-40 space-y-5 mr-5"
        >
          <p
            v-for="(nav, i) in navBar"
            :key="i"
            :class="{
              'text-text': active == nav.to,
            }"
            class="text-gray-300 b text-sm cursor-pointer"
            @click="routerDotPush(nav.to)"
          >
            {{ nav.label }}
          </p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import io from "socket.io-client";
export default {
  data() {
    return {
      user: [],
      isOpen: false,
      gameStatus: "",
      dif: 0,
      endAt: 0,
      startingTime: "",
      endingTime: "",
      socket: {},
      currentGameId: "",
      activeNavBar: "dashboard",
      navBar: [
        {
          label: "Dashboard",
          to: "dashboard",
        },
        {
          label: "Tickets",
          to: "tickets",
        },

        {
          label: "Employees",
          to: "employees",
        },
        // {
        //   label: "Report History",
        //   to: "report-history",
        // },
        {
          label: "Profile Settings",
          to: "profile-setting",
        },
      ],
    };
  },
  created() {
    // let user = localStorage.getItem("user");
    // this.user = JSON.parse(user);
    this.socket = io("https://server.proximastech.com");
    // VUE_APP_API_BASE_URL=https://server.proximastech.com
    // this.socket = io("http://192.168.1.9:7072");
    // this.socket = io("http://localhost:7072");
    this.$store.dispatch("Authentication/getMe").then((response) => {
      if (response) {
        // console.log("here");
        // console.log(
        //   "this.$store.state.Authentication.me;",
        //   this.$store.state.Authentication.me
        // );
        this.user = this.$store.state.Authentication.me;
      }
    });
  },

  mounted() {
    this.socket.on("message", (message) => {
      // console.log("message: ", message);
      this.dif = message.message.nextDate - message.createdAt;
      this.gameStatus = message.message.status;
      this.startingTime = message.createdAt;
      this.endingTime = message.message.nextDate;
      if (
        message.message.status == "newDrawnNumbers" ||
        message.message.status == "existingDrawnNumbers"
      ) {
        this.gameStatus = "Drawing";
        localStorage.setItem("gameIdforCashier", message.message.nextGameId);
        this.currentGameId = message.message.nextGameId;
      } else {
        this.gameStatus = "Ticket on sale";
        localStorage.setItem("gameIdforCashier", message.message.customId);
        this.currentGameId = message.message.customId;
      }
    });
  },
  computed: {
    returnUserName() {
      let userName = "";
      if (this.$store.state.Authentication.me) {
        userName = this.$store.state.Authentication.me.agentName;
      }
      return userName;
    },
    active() {
      return this.$route.name;
    },
    // NavBarFindByRole() {
    //   let sideBar = [];
    //   if (this.user.role) {
    //     let givenRole = this.user.role;
    //     console.log("log: ", this.user.role);
    //     this.navBar.forEach((icon) => {
    //       if (icon.Role.includes(givenRole)) sideBar.push(icon);
    //     });
    //   }
    //   return sideBar;
    // },
  },
  methods: {
    routerDotPush(to) {
      this.activeNavBar = to;
      this.$router.push({ name: to });
      this.isOpen = false;
    },
    logout() {
      this.socket.disconnect();
      const routeToHome = () => {
        // this.$store.dispatch('modals/sidebar', false)
        localStorage.clear();
        this.$store.dispatch("Authentication/logOut");
        // this.$store.dispatch('users/removingUserInfo')
        this.$router.push({
          path: "/",
        });
      };
      window.setTimeout(routeToHome, 1000);
    },
  },
};
</script>

<style scoped>
.dropdown:hover .dropdown-content {
  display: block;
  right: 0%;
  text-align: left;
}
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal-enter-active,
.modal-leave-active {
  transition: all 0.5s ease;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
  transform: scale(0.5);
}
.printer {
  position: fixed;
  display: flex;
  top: 15%;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: #2c2c2e;
}
.instruction {
  position: fixed;
  display: flex;
  top: 10%;
  left: 12%;

  /* transform: translate(-50%, -50%); */
  z-index: 99;
  /* width: 80%; */
  /* max-height: fit-content; */
  max-width: 100%;
  /* min-width: 56%;
  min-height: 50%; */
  background-color: #2c2c2e;
}
</style>
