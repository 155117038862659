import Vue from "vue";
import Vuex from "vuex";
// import { Socket } from "socket.io";
// import * as cashier from "./modules/cashier";
import * as tickets from "./modules/tickets";
import * as Authentication from "./modules/Authentication";
import * as notification from "./modules/notification.js";
// import * as users from "./modules/Users";
// import * as branches from "./modules/Agents";
import * as modals from "./modules/Modals";
import * as branchEmployees from "./modules/BranchEmployees";
// import * as branches from "./modules/Branches";
import * as keno from "./modules/Keno";
import * as agents from "./modules/Agents";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    // cashier,
    tickets,
    Authentication,
    notification,
    modals,
    branchEmployees,
    keno,
    agents,
  },
});
