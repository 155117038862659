<template>
  <div>
    <div class="flex justify-center py-4" v-if="noConnection">
      <network-issue />
    </div>
    <div v-if="!noConnection" class="p-5 text-white flex w-full">
      <div class="space-y-5 w-full">
        <div class="h-auto bg-darkcard rounded-lg p-4">
          <p class="text-white">Today's Report</p>
          <div class="hidden lg:block">
            <apexchart
              style="color: red"
              class="text-text"
              width="100%"
              height="350"
              type="area"
              :options="options"
              :series="series"
            ></apexchart>
          </div>
        </div>
        <div
          class="text-center w-full space-y-5 lg:hidden p-1 text-sm rounded-lg bg-darkcard"
        >
          <p class="w-full">Individual Cashier Report</p>
          <div>
            <div class="p-3 space-y-3">
              <div
                v-for="(i, index) in cashierReport"
                :key="index"
                class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-full text-center"
              >
                <div>{{ i.employeeName }}</div>
                <div class="text-white text-left text-base space-y-3">
                  <div class="flex space-x-3">
                    <!-- total Placed Money: {{ }} -->

                    <div>Total Gross income:</div>
                    <div class="text-yellow-500">
                      <div>
                        {{ i.totalGrossMoney.toLocaleString() }}
                      </div>
                    </div>
                  </div>
                  <div class="flex space-x-3">
                    <!-- total Placed Money: {{ }} -->

                    <div>Total Payouts:</div>
                    <div class="text-red-500">
                      <div>
                        {{ i.totalPayouts.toLocaleString() }}
                      </div>
                    </div>
                  </div>
                  <div class="flex space-x-3">
                    <!-- total Placed Money: {{ }} -->

                    <div class="">Total Profit:</div>
                    <div>
                      <div>
                        <p
                          :class="{
                            'text-redo': i.totalProfit < 0,
                            'text-green-500': i.totalProfit >= 0,
                          }"
                        >
                          {{ i.totalProfit.toLocaleString() }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center w-full space-y-5 lg:space-y-0 lg:flex lg:justify-around justify-center p-5 text-sm rounded-lg bg-darkcard"
        >
          <p class="lg:hidden text-xl">Total Report</p>
          <div class="lg:block flex justify-center">
            <div
              class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-56 text-center"
            >
              <div>Total Tickets Sold</div>
              <div class="text-indigo-500 space-x-3 text-center text-xl">
                <div>{{ animateTotalTickets.toLocaleString() }}</div>
              </div>
            </div>
          </div>

          <div class="lg:block flex justify-center">
            <div
              class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-56 text-center"
            >
              <div>Total Gross income</div>
              <div class="text-yellow-500 space-x-3 text-center text-xl">
                <div>
                  {{ animateTotalGrossIncome.toLocaleString() }}
                </div>
              </div>
            </div>
          </div>
          <div class="lg:block flex justify-center">
            <div
              class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-56 text-center"
            >
              <div>Total Payouts</div>
              <div class="text-red-400 space-x-3 text-center text-xl">
                <div>{{ animateTotalPayouts.toLocaleString() }}</div>
              </div>
            </div>
          </div>
          <div class="lg:block flex justify-center">
            <div
              class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-56 text-center"
            >
              <div>Total Profit</div>
              <div class="text-green-400 space-x-3 text-center text-xl">
                <div>
                  <p
                    :class="{
                      'text-redo': animateTotalProfit < 0,
                      'text-green-500': animateTotalProfit >= 0,
                    }"
                  >
                    {{ animateTotalProfit.toLocaleString() }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- {{ report }}/ -->
        </div>
      </div>
      <div class="object-contain">
        <!-- <kenoAdmin /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import gsap from "gsap";
import spinner from "@/components/spinner.vue";
import NetworkIssue from "@/Error/NetworkIssue.vue";
export default {
  components: {
    // KenoAdmin,
    spinner,
    NetworkIssue,
    // ApexCharts,
  },
  data() {
    return {
      newData: [],
      cashierReport: [],
      noConnection: false,
      spinner: false,
      totalGrossIncome: 0,
      animateTotalGrossIncome: (0).toFixed(0),
      totalPayouts: 0,
      animateTotalPayouts: (0).toFixed(0),
      totalTickets: 0,
      animateTotalTickets: (0).toFixed(0),
      totalProfit: 0,
      animateTotalProfit: (0).toFixed(0),
      totalEmployees: 0,
      animateTotalEmployees: (0).toFixed(0),
      options: {
        theme: {
          mode: "dark",
          palette: "palette10",
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: ["0", "ticket Sale", "Total income", "Payout", "Profit"],
        },
      },
      series: [
        {
          data: [],
        },
      ],
    };
  },

  created() {
    this.calculatingProfit();
    // this.calculatingOverAllProfit();

    // this.$store.dispatch("agents/getAllTimeReport").then((response) => {
    //   if (response) {
    //     this.spinner = false;
    //     this.totalGrossIncome = response.data.totalPlacedMoney;
    //     this.totalPayouts = response.data.totalPrizeMoney;
    //     this.totalProfit = response.data.totalProfit;
    //     this.totalTickets = response.data.results;
    //     this.totalEmployees = response.data.totalEmployees;
    //   } else {
    //     this.spinner = false;
    //     this.noConnection = true;
    //   }
    // });
    // this.$store.dispatch("agents/getTodayReports").then((response) => {
    //   if (response) {
    //     // this.spinner = false;
    //     console.log("loo: ", response.data.result);
    //     this.series = [
    //       {
    //         data: [
    //           "0",
    //           response.data.result,
    //           response.data.totalPlacedMoney,
    //           response.data.totalPayoutMoney,
    //           response.data.totalProfit,
    //         ],
    //       },
    //     ];
    //   }
    // });
  },
  watch: {
    totalGrossIncome(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalGrossIncome: newValue,
      });
    },
    totalPayouts(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalPayouts: newValue,
      });
    },
    totalTickets(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalTickets: newValue,
      });
    },
    totalProfit(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalProfit: newValue,
      });
    },
    totalEmployees(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalEmployees: newValue,
      });
    },
  },
  computed: {
    ...mapState("branchEmployees", ["employees"]),
    ...mapState("agents", ["report"]),
  },

  methods: {
    cashCalculator() {
      state.totalPrice = state.ticket.game.reduce(function (sum, price) {
        return sum + price.placedMoney;
      }, 0);
    },
    async calculatingProfit() {
      await this.$store
        .dispatch("branchEmployees/getAllEmployee")
        .then((response) => {
          if (response) {
            this.spinner = false;
            response.forEach((element) => {
              let one = [];
              one = {
                totalGrossMoney: element.unreportedTotalPlacedMoney,
                totalPayouts: element.unreportedTotalPayoutMoney,
                totalProfit:
                  element.unreportedTotalPlacedMoney -
                  element.unreportedTotalPayoutMoney,
                employeeName: element.employeeName,
              };

              this.cashierReport.push({ ...one });
              // console.log(this.cashierReport, "tttet");
            });
            // console.log("Response : ", response);
            this.totalGrossIncome = response.reduce((sum, i) => {
              return sum + i.unreportedTotalPlacedMoney;
            }, 0);
            // console.log("totalPlacedMoney: ", this.totalPlacedMoney);
            this.totalTickets = response.reduce((sum, i) => {
              return sum + i.unreportedTotalTickets;
            }, 0);
            // console.log("totalTickets: ", this.totalTickets);
            this.totalPayouts = response.reduce((sum, i) => {
              return sum + i.unreportedTotalPayoutMoney;
            }, 0);
            // console.log("totalTickets: ", this.totalPayouts);
            this.totalProfit = response.reduce((sum, i) => {
              return (
                sum +
                (i.unreportedTotalPlacedMoney - i.unreportedTotalPayoutMoney)
              );
            }, 0);
            // console.log("totalTickets: ", this.totalProfit);
            this.totalEmployees = response.length;
            this.series = [
              {
                data: [
                  "0",
                  this.totalTickets,
                  this.totalGrossIncome,
                  this.totalPayouts,
                  this.totalProfit,
                ],
              },
            ];
            // response.reduce((sum, i) => {
            //   return sum + i.totalEmployees;
            // }, 0);
            // console.log("totalEmployees: ", this.totalEmployees);
            // response.forEach((i) => {
            //   this.newData.i.totalPlacedMoney;
            // });
          } else {
            this.spinner = false;
            this.noConnection = true;
          }
        });
    },
    // calculatingOverAllProfit() {
    //   this.$store.dispatch("tickets/getAllTimeTickets").then((response) => {
    //     if (response) {
    //       console.log("All time Tickets: ", response);
    //     }
    //   });
    // },
    // assignDataToApexChart() {
    //   console.log("se; ", this.series[0].data[0]);
    //   // this.series[0].data[0] = this.report.result;
    //   // this.series[1].data[1] = this.report.totalPlacedMoney;
    //   // this.series[2].data[2] = this.report.totalPayoutMoney;
    //   // this.series[3].data[3] = this.report.totalProfit;
    //   // for (let i = 0; i < this.series.length; i++) {
    //   //   this.series.data[i].push(i.result)
    //   //   this.series.data[i].push(i.result)

    //   // }
    //   // if (this.report) {
    //   // this.report.forEach((i) => {
    //   //   this.series.data[2].push(i.result);
    //   //   this.series.data[2].push(i.totalPlacedMoney);
    //   //   this.series.data[2].push(i.totalPayoutMoney);
    //   //   this.series.data[2].push(i.totalProfit);
    //   // });
    //   // }
    // },
  },
};
</script>

<style></style>
