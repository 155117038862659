import axios from "axios";
import NProgress from "nprogress";
const API_URL = process.env.VUE_APP_API_BASE_URL;
import store from "@/store/store";
function res() {
  const instance = axios.create({
    baseURL: `${API_URL}/api/v1`,
    timeout: 20000,
  });
  instance.interceptors.request.use((config) => {
    NProgress.start();
    return config;
  });
  instance.interceptors.response.use(
    (response) => {
      NProgress.done();
      return response;
    },
    (error) => {
      NProgress.done();
      return Promise.reject(error);
    }
  );

  // console.log("response from axios: ", response);
  return instance;
}

export default {
  postLogin(formdata) {
    // console.log("post Login");
    return res().post(`/agents/login`, formdata);
  },
  postSignup(fromdata) {
    return res().post("/users/signup/", fromdata);
  },
  ResetPassword(formdata) {
    return res().patch(`/agents/resetPassword`, formdata);
  },
  ResetPasswordByLink({ token, formdata }) {
    return res().patch(`/agents/resetPasswordByLink/${token}`, formdata);
  },
  forgetPassword(formdata) {
    return res().post(`/agents/forgotPassword`, formdata);
  },
};
