<template>
  <div class="p-8">
    <div class="flex justify-between mx-auto mb-4">
      <p class="text-gray-400">Edit Employee info</p>
      <!-- <p class="fa fa-times text-red-500"></p> -->
      <img
        @click="close()"
        class="cursor-pointer"
        src="../../../assets/icons/x.svg"
        alt=""
        srcset=""
      />
    </div>
    <div class="lg:grid lg:grid-cols-2 gap5 text-center mb-8 bg-darkbg p-1">
      <div
        class="cursor-pointer"
        :class="{ 'text-text bg-darkcard': currentTab == 'basicInfo' }"
        @click="switchTab(1)"
      >
        Employee Info
      </div>

      <div
        class="cursor-pointer"
        :class="{ 'text-text bg-darkcard': currentTab == 'password' }"
        @click="switchTab(2)"
      >
        Employee Password
      </div>
    </div>
    <transition name="slide">
      <div v-if="currentTab == 'basicInfo'">
        <form
          @submit.prevent="onSubmit"
          class="w-full lg:grid lg:grid-cols-2 gap-5"
        >
          <div>
            <BaseInput
              label="name"
              inputClass="bg-darkbg outline-none w-full p-2 rounded "
              v-model.trim="formdata.employeeName"
              @blur="$v.formdata.employeeName.$touch()"
            />
            <div v-if="$v.formdata.employeeName.$error">
              <p
                class="text-xs text-red-600"
                v-if="!$v.formdata.employeeName.required"
                id="error_label"
              >
                Employee name is required!
              </p>
            </div>
          </div>
          <div>
            <BaseInput
              label="Email"
              type="Email"
              inputClass="bg-darkbg outline-none w-full p-2 rounded "
              v-model.trim="formdata.email"
              @blur="$v.formdata.email.$touch()"
            />
            <div v-if="$v.formdata.email.$error">
              <p
                class="text-xs text-red-500"
                v-if="!$v.formdata.email.required"
                id="error_label"
              >
                email is required!
              </p>
            </div>
            <div v-if="$v.formdata.email.$error && $v.formdata.email.required">
              <p
                class="text-xs text-red-500"
                v-if="!$v.formdata.email.email"
                id="error_label"
              >
                invalid email!
              </p>
            </div>
          </div>
          <div>
            <BaseInput
              autocomplete="off"
              label="Phone Number"
              inputClass="bg-darkbg outline-none w-full p-2 rounded "
              v-model.trim="formdata.phoneNumber"
              type="number"
              @blur="$v.formdata.phoneNumber.$touch()"
            />
            <div v-if="$v.formdata.phoneNumber.$error">
              <p
                class="text-xs text-red-500"
                v-if="!$v.formdata.phoneNumber.required"
                id="error_label"
              >
                Phone number is required!
              </p>
            </div>
          </div>
          <div class="inline-block relative w-full">
            <p>Role</p>
            <select
              class="bg-darkbg outline-none w-full p-2 rounded"
              style=""
              v-model.trim="formdata.role"
              @blur="$v.formdata.role.$touch()"
            >
              <option class="bg-darkcard rounded-none" value="payoutGiver">
                Agent
              </option>
              <option class="bg-darkcard rounded-none" value="cashier">
                Cashier
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <!-- <i class="fa fa-angle-down"></i> -->
            </div>
            <div v-if="$v.formdata.role.$error">
              <p
                class="text-xs text-red-500"
                v-if="!$v.formdata.role.required"
                id="error_label"
              >
                Role is required!
              </p>
            </div>
          </div>
        </form>
        <div class="w-full">
          <BaseButton
            @click="onSubmit"
            buttonClass="w-full cursor-pointer flex text-base justify-center mt-5 p-2 bg-text"
            >Edit Employee</BaseButton
          >
          <p v-if="error" class="text-xs text-red-600">
            please fill out all the form correctly!
          </p>
        </div>
      </div>

      <div v-if="currentTab == 'password'">
        <form
          @submit.prevent="onSubmit"
          class="w-full lg:grid lg:grid-cols-2 gap-5"
        >
          <div class="relative">
            <BaseInput
              autocomplete="off"
              label="Password"
              :passwordInput="true"
              inputClass="bg-darkbg outline-none w-full p-2 rounded "
              v-model="passwordInfo.password"
              @blur="$v.passwordInfo.password.$touch()"
            />
            <div v-if="$v.passwordInfo.password.$error">
              <p
                class="text-xs text-red-500"
                v-if="!$v.passwordInfo.password.required"
                id="error_label"
              >
                password is required!
              </p>
            </div>
            <div v-if="$v.passwordInfo.password.$error">
              <p
                class="text-xs text-red-600"
                v-if="!$v.passwordInfo.password.minLength"
                id="error_label"
              >
                Password must be at least 8 characters!
              </p>
            </div>
          </div>
          <div class="relative">
            <BaseInput
              autocomplete="off"
              class=""
              :passwordInput="true"
              inputClass="bg-darkbg outline-none w-full p-2 rounded "
              label="Confirm Password"
              v-model="passwordInfo.passwordConfirm"
              @blur="$v.passwordInfo.passwordConfirm.$touch()"
            />
            <div v-if="$v.passwordInfo.passwordConfirm.$error">
              <p
                class="text-xs text-red-500"
                v-if="!$v.passwordInfo.passwordConfirm.sameAs"
                id="error_label"
              >
                Passwords don't match!
              </p>
            </div>
          </div>
        </form>
        <div class="w-full">
          <BaseButton
            @click="submitPassword()"
            buttonClass="w-full cursor-pointer flex text-base justify-center mt-5 p-2 bg-text"
            >Update Password</BaseButton
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      error: false,
      status: false,
      currentTab: "basicInfo",
      show: false,
      showConfirm: false,
      formdata: {
        email: "",
        phoneNumber: "",
        firstName: "",
        employeeName: "",
        role: "cashier",
      },
      passwordInfo: {
        password: "",
        passwordConfirm: "",
      },
    };
  },
  props: {
    info: {
      required: true,
    },
  },
  validations: {
    formdata: {
      email: { required, email },
      employeeName: { required },
      phoneNumber: { required },

      role: { required },
    },
    passwordInfo: {
      passwordConfirm: { required, passwordConfirm: sameAs("password") },
      password: { required, minLength: minLength(8) },
    },
  },
  computed: {
    ...mapState("branchEmployees", ["singleEmployee"]),
    ...mapGetters("modals", ["editEmployeeStatus"]),
  },
  mounted() {
    this.populate();
  },
  methods: {
    populate() {
      this.formdata.employeeName = this.info.employeeName;
      this.formdata.email = this.info.email;
      this.formdata.phoneNumber = this.info.phoneNumber;
      this.formdata.role = this.info.role;
    },
    close() {
      this.$store.dispatch("modals/closeAll");
    },
    switchTab(index) {
      if (index == 1) {
        this.currentTab = "basicInfo";
      } else {
        this.currentTab = "password";
      }
    },

    onSubmit() {
      this.$v.formdata.$touch();
      if (!this.$v.formdata.$invalid) {
        this.$store
          .dispatch("branchEmployees/updateEmployeeProfileByBranchManager", {
            id: this.info.id,
            payload: this.formdata,
          })
          .then((response) => {
            if (response) {
              this.$parent.$parent.fetchEmployees();
              this.$store.dispatch("modals/closeAll");
            }
          });
      } else {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
    submitPassword() {
      this.$v.passwordInfo.$touch();
      if (!this.$v.passwordInfo.$invalid) {
        const data = {
          id: this.info.id,
          payload: this.passwordInfo,
        };
        console.log("data vue: ", data);
        this.$store
          .dispatch(
            "branchEmployees/updateEmployeePasswordByBranchManager",
            data
          )
          .then((response) => {
            if (response && !response.error) {
              this.$parent.$parent.fetchEmployees();
              this.$store.dispatch("modals/closeAll");
            }
          });
      } else {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
  },
};
</script>

<style>
.tab-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
}

.slide-left-enter,
.slide-right-leave-active {
  transform: translateX(100%);
}

.slide-right-enter,
.slide-left-leave-active {
  transform: translateX(-100%);
}
</style>
