/* eslint-disable */
import Vue from "vue";
import AxiosService from "../../services/AxiosServices";
import ErrorHandler from "../../services/ErrorHandler";
import router from "@/router";

export const namespaced = true;
export const state = {
  employees: [],
  Transaction: [],
  singleEmployee: [],
  searchEmployee: [],
  me: [],
  trueRole: "",
};
export const mutations = {};

export const actions = {
  // get Requests
  getMe({ state, dispatch }) {
    return AxiosService.getMeBranchEmployee()
      .then((res) => {
        // console.log('response" ', res.data.user.role);
        state.me = res.data.user;
        state.trueRole = res.data.user.role;
        return res.data.user.role;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  getAllEmployee({ state, dispatch }) {
    return AxiosService.getAllEmployee()
      .then((res) => {
        state.employees = res.data.user;
        // console.log("branchEmployees :", res.data.user);
        return res.data.user;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  getEmployeeTransaction({ state, dispatch }, payload) {
    return AxiosService.getEmployeeTransaction(payload)
      .then((res) => {
        // state.Transaction = res.data.doc;
        // console.log("Transaction: ", res.data.doc);
        return res.data.doc;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  getEmployeeTransactionCount({ state, dispatch }, id) {
    return AxiosService.getEmployeeTransactionCount(id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  getEmployeeById({ state, dispatch }, id) {
    return AxiosService.getEmployeeById(id)
      .then((res) => {
        state.singleEmployee = res.data.user;
        return true;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  searchEmployee({ state, dispatch }, search) {
    AxiosService.searchEmployee(search)
      .then((res) => {
        let Result = res.data.bet;
        state.searchEmployee.push({ Result });
        // console.log("search", res.data);
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  // Post Request
  createEmployee({ state, dispatch }, payload) {
    return AxiosService.createEmployee(payload).then((res) => {
      AxiosService.getAllEmployee().then((res) => {
        state.employees = res.data.user;
        // console.log("branchEmployees :", res.data.user);
      });
      const notification = {
        type: "success",
        message: "You have created employee successfully!",
      };
      dispatch("notification/add", notification, { root: true });
      return true;
    });
  },
  //   Patch
  activateEmployeeAccount({ state, dispatch }, payload) {
    AxiosService.activateEmployeeAccount(payload)
      .then((res) => {
        AxiosService.getAllEmployee().then((res) => {
          state.employees = res.data.user;
          // console.log("branchEmployees :", res.data.user);
        });
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  closeTodaysReport({ state, dispatch }, id) {
    return AxiosService.closeTodaysReport(id)
      .then((res) => {
        return AxiosService.getAllEmployee().then((res) => {
          state.employees = res.data.user;
          return true;
          // console.log("branchEmployees :", res.data.user);
        });
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  forgotEmployeePassowrd({ state, dispatch }, payload) {
    AxiosService.forgotEmployeePassowrd(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  ResetEmployeePassword({ state, dispatch }, payload) {
    AxiosService.ResetEmployeePassword(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  updateMyProfileEmployee({ state, dispatch }, payload) {
    AxiosService.updateMyProfileEmployee(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  updateMyPasswordEmployee({ state, dispatch }, payload) {
    AxiosService.updateMyPasswordEmployee(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  updateEmployeeProfileByBranchManager({ state, dispatch }, { id, payload }) {
    // console.log("id: ", id);
    // console.log("payload: ", payload);
    return AxiosService.updateEmployeeProfileByBranchManager(id, payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  updateEmployeePasswordByBranchManager({ state, dispatch }, data) {
    // console.log("data: ", data);
    return AxiosService.updateEmployeePasswordByBranchManager(data)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
};
export const getters = {};
