import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
const API_URL = process.env.VUE_APP_API_BASE_URL;
import CryptoJS from "crypto-js";
const key = "itain'tbeingalone";
function res() {
  // let tk_value = localStorage.getItem("token");
  const encryptedToken = localStorage.getItem("encryptedBranchToken");
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, key);
  const tk_value = decryptedBytes.toString(CryptoJS.enc.Utf8);
  const instance = axios.create({
    baseURL: `${API_URL}/api/v1`,
    timeout: 20000,
    headers: {
      authorization: "Bearer " + tk_value,
    },
  });
  instance.interceptors.request.use((config) => {
    NProgress.start();
    return config;
  });
  instance.interceptors.response.use(
    (response) => {
      NProgress.done();
      return response;
    },
    (error) => {
      NProgress.done();
      return Promise.reject(error);
    }
  );

  // console.log("response from axios: ", response);
  return instance;
}
// import store from "@/store/store";
// function networkIssue() {
//   const message = {
//     type: "error",
//     message: "Please try again!",
//   };
//   store.dispatch("notification/add", message);
// }
// function ntwErr() {
//   const message = {
//     type: "error",
//     message: "It seems there is network issue please check your connection!",
//   };
//   store.dispatch("notification/add", message);
// }

// function res() {
//   const encryptedToken = localStorage.getItem("encryptedBranchToken");
//   const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, key);
//   const tk_value = decryptedBytes.toString(CryptoJS.enc.Utf8);
//   // let tk_value = localStorage.getItem("encryptedBranchToken");
//   let response = axios.create({
//     baseURL: `${API_URL}/api/v1`,
//     timeout: 200000,
//     headers: {
//       authorization: "Bearer " + tk_value,
//     },
//   });
//   response.interceptors.request.use((config) => {
//     NProgress.start();
//     return config;
//   });
//   response.interceptors.response.use((config) => {
//     NProgress.done();
//     return config;
//   });
//   // console.log("response from axios: ", response);
//   return response;
// }

export default {
  getMe() {
    return res().get("/agents/profile/");
  },
  updateMyProfile(payload) {
    return res().patch("/agents/updateProfile/", payload);
  },
  updateMyPassword(payload) {
    return res().patch("/agents/updateMyPassword/", payload);
  },
  sendForDevTeam(payload) {
    return res().post("/comments/", { comment: payload });
  },
  //CASHIER
  // Get Requests
  getAllTickets(perPage, page) {
    return res().get(
      `/tickets/agent/todayTicketsHistory?sort=-createdAt&limit=${perPage}&page=${page}`
    );
  },
  getCountAllTickets() {
    return res().get("/tickets/countAgentTickets");
  },
  getAllTimeReport() {
    return res().get("/agents/statusData");
  },
  getTicketById(id) {
    return res().get(`/tickets/${id}`);
  },
  getEmployeeTransaction(payload) {
    return res().get(
      `/reports/employee/${payload.id}?sort=-createdAt&limit=${payload.perPage}&page=${payload.page}`
    );
  },
  getEmployeeTransactionCount(id) {
    return res().get(`/reports/agent/employee/countDocuments/${id}`);
  },
  getAllTicketsByDate(date) {
    return res().get(`/tickets/${date}`);
  },
  searchTicket(search) {
    // console.log("ov");
    return res().get(`/tickets/agent/search?q=${search}`);
  },
  searchGame(search) {
    return res().get(`/gameResults/agent/search?q=${search}`);
  },
  // Get Request
  todayReport() {
    return res().get("/tickets/agent/todayReport/");
  },
  getMeBranchEmployee() {
    return res().get("/branchEmployees/profile/");
  },
  getAllEmployee() {
    return res().get("/branchEmployees/");
  },
  getEmployeeById(id) {
    return res().get(`/branchEmployees/${id}`);
  },
  searchEmployee(search) {
    return res().get(`/branchEmployees/search/${search}`);
  },
  // Post Requests
  createEmployee(payload) {
    return res().post("/branchEmployees/signup", payload);
  },
  // Patch Request
  activateEmployeeAccount(id) {
    return res().patch(`/branchEmployees/changeActiveStatus/${id}`);
  },
  closeTodaysReport(id) {
    return res().patch(`/branchEmployees/markReport/${id}`);
  },
  updateEmployeeProfileByBranchManager(id, payload) {
    return res().patch(`/branchEmployees/${id}`, payload);
  },
  updateEmployeePasswordByBranchManager(data) {
    // console.log("data Axios: ", data);
    return res().patch(
      `branchEmployees/agent/updateEmployeePassword/${data.id}`,
      data.payload
    );
  },
  updateMyProfileEmployee(payload) {
    return res().patch("/branchEmployees/updateProfile", payload);
  },
  updateMyPasswordEmployee(payload) {
    return res().patch("/branchEmployees/updateMyPassword/", payload);
  },
  forgotEmployeePassword(payload) {
    return res().patch("/branchEmployees/forgotPassword/", payload);
  },
  ResetEmployeePassword(payload) {
    return res().patch("/branchEmployees/resetPassword/", payload);
  },
};
