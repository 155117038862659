<template>
  <div>
    <div class="flex justify-center py-4" v-if="noConnection">
      <network-issue />
    </div>
    <div
      v-if="!noConnection"
      class="bg-darkcard mx-4 my-1 text-gray-300 rounded-md p-3"
    >
      <BaseModal :visible="addEmployeeStatus"> <AddEmployee /></BaseModal>
      <BaseModal :visible="editEmployeeStatus">
        <EditEmployee :info="infoToBeEdited"
      /></BaseModal>
      <BaseModal :visible="reportModalStatus">
        <ReportModal :dataInfo="reportData"
      /></BaseModal>
      <div class="grid grid-cols-7 text-base">
        <div class="col-span-5 text-sm">Employees</div>
        <div>
          <BaseButton
            @click="modal"
            icon="fa fa-plus"
            buttonClass="bg-text px-2  text-xm"
          >
            Add
          </BaseButton>
        </div>
      </div>
      <div class="bg-darkbg my-2 rounded-xl overflow-x-auto">
        <table class="table-auto w-full text-sm overflow-x-auto">
          <thead>
            <tr class="space-x-8 text-left font-light">
              <th class="border-b border-b-gray-700 px-1 font-light py-2">#</th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Employee Name
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Employee Info
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Employee Role
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Tickets
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Income
              </th>

              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Payout
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Profit
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Report
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Status
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Weekly Report
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(emp, index) in employees"
              :key="index"
              class="text-gray-400"
            >
              <td class="px-1 py-2">{{ index + 1 }}</td>
              <td class="px-1 py-2">{{ emp.employeeName }}</td>
              <td class="px-1 py-2">{{ emp.phoneNumber }}</td>
              <td class="px-1 py-2">{{ emp.role }}</td>
              <td class="px-1 py-2">
                {{ emp.unreportedTotalTickets }}
              </td>
              <td class="px-1 py-2 text-1">
                {{ emp.unreportedTotalPlacedMoney }}
              </td>

              <td class="px-1 py-2 text-red-500">
                <!-- ({{ emp.totalWonticketsCount }}) -->
                {{ emp.unreportedTotalPayoutMoney }} birr
              </td>

              <td
                class="px-1 text-green-500 flex space-x-2 py-2"
                :class="{
                  ' text-green-500':
                    Number(emp.unreportedTotalPlacedMoney) -
                      Number(emp.unreportedTotalPayoutMoney) >
                    0,
                  ' text-redo':
                    Number(emp.unreportedTotalPlacedMoney) -
                      Number(emp.unreportedTotalPayoutMoney) <
                    0,
                }"
              >
                <!-- <p>{{ emp.totalProfit }} birr</p> -->
                {{
                  emp.unreportedTotalPlacedMoney -
                  emp.unreportedTotalPayoutMoney
                }}
              </td>

              <td class="px-1 object-contain space-x-5 text-center py-2">
                <div class="flex space-x-3">
                  <div
                    v-if="
                      Number(emp.unreportedTotalPlacedMoney) -
                        Number(emp.unreportedTotalPayoutMoney) !==
                      0
                    "
                  >
                    <BaseButton
                      @click="closeTodaysReport(emp)"
                      class="bg-blue-500 text-xs text-white p-1"
                      >Report</BaseButton
                    >
                  </div>
                  <div
                    v-if="
                      Number(emp.unreportedTotalPlacedMoney) -
                        Number(emp.unreportedTotalPayoutMoney) ===
                      0
                    "
                    class="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]"
                  >
                    0
                  </div>
                </div>
              </td>
              <td class="px-1 object-contain space-x-5 text-left py-2">
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    @click="toggle(emp.id, emp.active)"
                    v-model="status[emp.id]"
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                  />
                  <div
                    class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                  ></div>
                </label>
                <!-- <label class="switch" for="flexSwitchCheckDefault">
                  <input
                    class="switch-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    @click="toggle(emp.id, emp.active)"
                    v-model="status[emp.id]"
                  />

                  <span class="switch-label"></span>
                  <span class="switch-handle"></span>
                </label> -->
              </td>
              <!-- <td class="px-1 object-contain space-x-5 text-left py-2">
                
                <input
                  class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  @click="toggle(emp.id, emp.active)"
                  v-model="status[emp.id]"
                />
              </td> -->
              <td class="px-2 object-contain space-x-5 text-right flex py-2">
                <p
                  class="cursor-pointer"
                  @click="getEmployeeTransaction(emp.id)"
                >
                  More
                </p>
              </td>
              <!-- <td class="px-2 object-contain space-x-5 text-right flex py-2">
              <img
                @click="editEmployeeInfo(emp.id)"
                class="w-4 h-4"
                src="../../assets/icons/edit.svg"
                alt=""
                srcset=""
              />
            </td> -->
              <td>
                <BaseButton :disabled="edit" @click="editEmployeeInfo(emp)"
                  ><img
                    class="w-4 h-4"
                    src="../../assets/icons/edit.svg"
                    alt=""
                    srcset=""
                /></BaseButton>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="text-3xl py-24 w-full text-center"
          v-if="employees.length == 0"
        >
          There are no employees available!
        </div>
      </div>
      <div class="text-xs flex justify-end">
        <div class="flex space-x-5">
          <div class="flex space-x-8">
            <template v-if="page !== 1">
              <router-link :to="{ query: { page: page - 1 } }" rel="prev">
                ← Prev Page
              </router-link>
            </template>
            <div
              class="ml-2 text-sm cursor-pointer"
              :class="{
                'bg-text m w-8 rounded-md text-white text-center':
                  page === $route.query.page,
              }"
              @click="paginate(page)"
              v-for="(page, i) in letsTry"
              :key="i"
            >
              {{ page }}
            </div>
            <template v-if="page < letsTry.length">
              <router-link :to="{ query: { page: page + 1 } }" rel="prev"
                >Next Page →</router-link
              >
            </template>
          </div>
          <!-- <div>1 of 56 pages</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import NetworkIssue from "@/Error/NetworkIssue.vue";
import ReportModal from "@/components/Modal/ReportModal.vue";
import spinner from "@/components/spinner.vue";
import AddEmployee from "../../components/Modal/Employees/AddEmployee.vue";
import EditEmployee from "../../components/Modal/Employees/EditEmployee.vue";
export default {
  components: {
    AddEmployee,
    EditEmployee,
    spinner,
    NetworkIssue,
    ReportModal,
  },
  data() {
    return {
      reportData: [],
      noConnection: false,
      infoToBeEdited: [],
      search: "",
      edit: false,
      status: {},
      zero: true,
    };
  },
  created() {
    this.fetchEmployees();
  },
  computed: {
    ...mapGetters("modals", ["addEmployeeStatus"]),
    ...mapGetters("modals", ["editEmployeeStatus"]),
    ...mapState("branchEmployees", ["employees"]),
    ...mapGetters("modals", ["reportModalStatus"]),
    letsTry() {
      let page = [];
      let pages = this.allTickets / 13;
      for (var i = 1; i < pages + 1; i++) {
        page.push(i);
      }
      return page;
    },
    page() {
      return parseInt(this.$route.query.page) || 1;
    },
    letsRun() {
      if (this.employees) {
        return this.arrangeToggle();
      }
    },
  },
  methods: {
    fetchEmployees() {
      this.$store
        .dispatch("branchEmployees/getAllEmployee")
        .then((response) => {
          if (response) {
            this.spinner = false;
            this.arrangeToggle();
          } else {
            this.spinner = false;
            this.noConnection = true;
          }
        });
    },
    arrangeToggle() {
      this.status = {};
      // console.log("hehe");
      if (this.employees) {
        // console.log("overhh: ", this.employees);
        this.employees.forEach((b) => {
          this.status[b.id] = b.active;
          // console.log("stat.active", b.active);
        });
      }
    },
    getEmployeeTransaction(id) {
      this.$router.push({ name: "transaction-log", params: { id: id } });
    },
    editEmployeeInfo(emp) {
      this.infoToBeEdited = emp;
      this.editEmployee();
    },
    modal() {
      this.$store.dispatch("modals/modal", {
        stat: "addEmployee",
        status: !this.addEmployeeStatus,
      });
    },
    editEmployee() {
      this.$store.dispatch("modals/modal", {
        stat: "editEmployee",
        status: !this.editEmployeeStatus,
      });
    },
    paginate(selectedPage) {
      this.$router.push({ query: { page: selectedPage } });
      this.$store.dispatch("tickets/getAllTickets", {
        perPage: 13,
        page: this.page,
      });
    },
    closeTodaysReport(data) {
      this.reportData = data;
      this.$store.dispatch("modals/modal", {
        stat: "reportModal",
        status: !this.reportModalStatus,
      });
      // this.$store.dispatch("branchEmployees/closeTodaysReport", id);
    },
    searchEmployee() {
      this.$store.dispatch("branchEmployees/searchEmployee", this.search);
    },
    toggle(id, status) {
      // console.log("id: ", id);
      this.mode = !status;
      this.$store.dispatch("branchEmployees/activateEmployeeAccount", id);
    },
  },
};
</script>

<style></style>
