<template>
  <div>
    <label class="mb-5 text-gray-300 text-sm" v-if="label">{{ label }}</label>
    <div class="relative">
      <input
        class="placeholder-gray-500 pr-10"
        :value="value"
        @input="updateValue"
        v-bind="$attrs"
        v-on="listeners"
        :class="inputClass"
        :type="inputType"
      />
      <div
        v-if="passwordInput"
        class="absolute top-0 right-0 h-full flex items-center pr-2 cursor-pointer"
        @click="togglePasswordVisibility"
      >
        <BaseSee v-if="passwordVisible" />
        <BaseHide v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../../mixins/mixins";

export default {
  props: {
    inputClass: {},
    passwordInput: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [mixin],
  data() {
    return {
      show: false,
      passwordVisible: false,
    };
  },
  created() {
    this.show = this.passwordInput;
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      };
    },
    inputType() {
      // console.log("passwordInput: ", this.passwordInput);
      return this.show ? "password" : "text";
    },
    showPassword() {
      return this.passwordInput ? "password" : "text";
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
      // console.log("here");
      this.show = !this.show;
      // this.inputType = this.passwordVisible ? "text" : "password";
    },
  },
};
</script>

<style scoped>
.new {
  border-radius: 18px;
}
.grouplink {
  font: small;
  background-color: #e9e9e9;
  border-radius: 5px;
  padding-right: 0;
  width: 330px;
}
.groupname {
  font: small;
  background-color: #e9e9e9;
  border-radius: 5px;
  padding-right: 0;
  width: 530px;
}
/* .groupdescription {
  font: small;
  background-color: #e9e9e9;
  border-radius: 5px;
  padding-right: 0;
  width: 530px;
  height: 800px;
} */
</style>
