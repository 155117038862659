/* eslint-disable */
import Vue from "vue";
import AxiosService from "../../services/AxiosServices";
import ErrorHandler from "../../services/ErrorHandler";

export const namespaced = true;
export const state = {
  allTickets: 0,
  tickets: [],
  singleTicket: [],
  searchResult: [],
  totalPrice: 0,
};

export const actions = {
  // get Requests
  getAllTimeTickets({ state, dispatch }) {
    return AxiosService.getAllTimeTickets().then((res) => {
      return res.data.doc;
    });
  },
  searchGameTicketRecall({ state, dispatch }, search) {
    return AxiosService.searchGame(search).then((res) => {
      return res;
    });
  },
  getAllTickets({ state, dispatch }, { perPage, page }) {
    return AxiosService.getAllTickets(perPage, page)
      .then((res) => {
        state.ticket = [];
        state.tickets = res.data.doc;
        return res.data.doc;
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  },
  searchGame({ state, dispatch }, search) {
    return AxiosService.searchGame(search)
      .then((res) => {
        return res.data.doc;
      })
      .catch((error) => {
        // ErrorHandler(error);
        return false;
      });
  },
  getCountAllTickets({ state, dispatch }) {
    AxiosService.getCountAllTickets().then((res) => {
      state.allTickets = res.data.result;
    });
  },
  getTicketById({ state }, id) {
    AxiosService.getTicketById(id)
      .then((res) => {
        state.singleTicket = res.data;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  searchTicket({ state }, search) {
    return AxiosService.searchTicket(search)
      .then((res) => {
        let Result = res.data;
        state.tickets = res.data;
        return res.data.doc;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  getAllTicketsByDate({ state }, date) {
    AxiosService.getAllTicketsByDate(date)
      .then((res) => {
        state.tickets = res.data;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
};
export const getters = {};
