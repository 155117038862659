/* eslint-disable */
import Vue from "vue";
export const namespaced = true;
export const state = {
  addEmployee: false,
  editEmployee: false,
  gameDetails: false,
  reportModal: false,
};

export const actions = {
  modal({ state }, { stat, status }) {
    state[stat] = status;
    // console.log("fired: ", stat, status);
  },
  closeAll({ state }) {
    Object.keys(state).forEach((key) => {
      state[key] = false;
    });
  },
};
export const getters = {
  addEmployeeStatus: (state) => state.addEmployee,
  editEmployeeStatus: (state) => state.editEmployee,
  gameDetailsStatus: (state) => state.gameDetails,
  reportModalStatus: (state) => state.reportModal,
};
