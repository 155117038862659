<template>
  <div>
    <img
      class="text-1 h-6 w-6"
      src="../../assets/eyeslashsvgrepocom.svg"
      alt=""
      srcset=""
    />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
