/* eslint-disable */
import Vue from "vue";
import AxiosService from "../../services/AxiosServices";
import router from "@/router";

export const namespaced = true;
export const state = {
  agents: [],
  singleAgent: [],
  singleBranch: [],
  searchAgent: [],
  me: [],
  report: [],
};
export const mutations = {};

export const actions = {
  // get Requests
  getMe({ state, dispatch }) {
    return AxiosService.getMe()
      .then((res) => {
        // console.log('response" ', res.data.user.role);
        state.me = res.data.user;
        state.trueRole = res.data.user.role;
        return res.data.user.role;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  getAllAgents({ state, dispatch }) {
    AxiosService.getAllAgents()
      .then((res) => {
        state.agents = res.data;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  getAgentById({ state, dispatch }, id) {
    AxiosService.getAgentById(id)
      .then((res) => {
        state.singleAgent = res.data;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  getSingleBranch({ state, dispatch }, id) {
    AxiosService.getSingleBranch(id)
      .then((res) => {
        state.singleBranch = res.data;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  getAllTimeReport({ state, dispatch }) {
    return AxiosService.getAllTimeReport()
      .then((res) => {
        state.allTimeReport = res.data;
        // console.log("Tickets: ", res.data);
        return res;
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  getTodayReports({ state }) {
    return AxiosService.todayReport().then((res) => {
      state.report = res.data;
      // console.log("Tickets: ", res.data);
      return res;
    });
    // .catch((error) => {
    // console.log("errro form u: ", error);
    //   if (error.code) {
    // console.log("erororro: ", error.code);
    //     if (error.code == "ERR_NETWORK") {
    //       const notification = {
    //         type: "error",
    //         message:
    //           "It seems there is network issue please check your connection!",
    //       };
    //       dispatch("notification/add", notification, { root: true });
    //     } else if (error.code == "ECONNABORTED") {
    //       const notification = {
    //         type: "error",
    //         message: "Please try again!",
    //       };
    //       dispatch("notification/add", notification, { root: true });
    //     } else if (error.code == "ERR_BAD_REQUEST") {
    //       if (error.response.data.error.statusCode == 401) {
    //         localStorage.clear();
    //         router.push("/");
    //         const notification = {
    //           type: "error",
    //            message:
    // "Unauthorized navigation, please contact your branch manager!",
    //         };
    //         dispatch("notification/add", notification, { root: true });
    //         return false;
    //       } else {
    //         const notification = {
    //           type: "error",
    //           message: error.response.data.message,
    //         };
    //         dispatch("notification/add", notification, { root: true });
    //         return false;
    //       }
    //     }
    //   }
    // });
  },
  searchAgent({ state, dispatch }, id) {
    AxiosService.searchAgent(id)
      .then((res) => {
        let Result = res.data.bet;
        state.searchAgent.push({ Result });
        // console.log("search", res.data);
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  // Post Request
  createAgent({ state, dispatch }, payload) {
    AxiosService.createAgent(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "bet created successfully!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  //   Patch
  editEmployeeByAgent({ state, dispatch }, { id, payload }) {
    // console.log("data id: ", id);
    // console.log("data payload: ", payload);
    AxiosService.editEmployeeByAgent({ id, payload })
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  activateAgentAccount({ state, dispatch }, payload) {
    AxiosService.activateAgentAccount(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  updateMyProfile({ state, dispatch }, payload) {
    AxiosService.updateMyProfile(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  updateMyPassword({ state, dispatch }, payload) {
    AxiosService.updateMyPassword(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
  updateMyProfileByProxima({ state, dispatch }, id) {
    AxiosService.updateMyProfileByProxima(id)
      .then((res) => {
        const notification = {
          type: "success",
          message: "sucess!",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        // console.log("errro form u: ", error);
        if (error.code) {
          // console.log("erororro: ", error.code);
          if (error.code == "ERR_NETWORK") {
            const notification = {
              type: "error",
              message:
                "It seems there is network issue please check your connection!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ECONNABORTED") {
            const notification = {
              type: "error",
              message: "Please try again!",
            };
            dispatch("notification/add", notification, { root: true });
          } else if (error.code == "ERR_BAD_REQUEST") {
            if (error.response.data.error.statusCode == 401) {
              localStorage.clear();
              router.push("/");
              const notification = {
                type: "error",
                message:
                  "Unauthorized navigation, please contact your branch manager!",
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            } else {
              const notification = {
                type: "error",
                message: error.response.data.message,
              };
              dispatch("notification/add", notification, { root: true });
              return false;
            }
          } else {
            // console.log("obvehee: ");
            const notification = {
              type: "error",
              message: error.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        }
      });
  },
};
export const getters = {};
