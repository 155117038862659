<template>
  <div class="text-white lg:grid lg:grid-cols-2">
    <div class="p-2 w-full">
      <form
        @submit.prevent="updateProfileFun"
        class="bg-darkcard p-3 w-full lg:grid lg:grid-row-3 gap-5"
      >
        <div>Change Profile</div>
        <div>
          <BaseInput
            label="name"
            inputClass="bg-darkbg outline-none w-full p-2 rounded "
            v-model.trim="updateProfile.agentName"
            @blur="$v.updateProfile.agentName.$touch()"
          />
          <div v-if="$v.updateProfile.agentName.$error">
            <p
              class="text-xs text-red-600"
              v-if="!$v.updateProfile.agentName.required"
              id="error_label"
            >
              Employee name is required!
            </p>
          </div>
        </div>
        <div>
          <BaseInput
            label="Email"
            type="Email"
            inputClass="bg-darkbg outline-none w-full p-2 rounded "
            v-model.trim="updateProfile.email"
            @blur="$v.updateProfile.email.$touch()"
          />
          <div v-if="$v.updateProfile.email.$error">
            <p
              class="text-xs text-red-500"
              v-if="!$v.updateProfile.email.required"
              id="error_label"
            >
              email is required!
            </p>
          </div>
          <div
            v-if="
              $v.updateProfile.email.$error && $v.updateProfile.email.required
            "
          >
            <p
              class="text-xs text-red-500"
              v-if="!$v.updateProfile.email.email"
              id="error_label"
            >
              invalid email!
            </p>
          </div>
        </div>
        <div>
          <BaseInput
            autocomplete="off"
            label="Phone Number"
            inputClass="bg-darkbg outline-none w-full p-2 rounded "
            v-model.trim="updateProfile.phoneNumber"
            type="number"
            @blur="$v.updateProfile.phoneNumber.$touch()"
          />
          <div v-if="$v.updateProfile.phoneNumber.$error">
            <p
              class="text-xs text-red-500"
              v-if="!$v.updateProfile.phoneNumber.required"
              id="error_label"
            >
              Phone number is required!
            </p>
          </div>
        </div>
        <div class="w-full">
          <BaseButton
            buttonClass="w-full"
            class="w-full cursor-pointer flex text-base justify-center mt-2 p-2 bg-text"
            @click="updateProfileFun()"
            >Update Profile</BaseButton
          >
          <p v-if="error" class="text-xs text-red-600">
            please fill out all the form correctly!
          </p>
        </div>
      </form>
    </div>
    <div class="p-2 w-full">
      <form
        @submit.prevent="updatePasswordFun"
        class="bg-darkcard p-3 w-full grid grid-row-3 gap-5"
      >
        <div>Change Password</div>

        <div class="">
          <BaseInput
            :passwordInput="true"
            autocomplete="off"
            label="current password"
            inputClass="bg-darkbg outline-none w-full p-2 rounded "
            v-model="updatePassword.oldPassword"
            @blur="$v.updatePassword.oldPassword.$touch()"
          />
          <div v-if="$v.updatePassword.oldPassword.$error">
            <p
              class="text-xs text-red-500"
              v-if="!$v.updatePassword.oldPassword.required"
              id="error_label"
            >
              current password is required!
            </p>
          </div>
        </div>
        <div class="">
          <BaseInput
            :passwordInput="true"
            autocomplete="off"
            label="New Password"
            inputClass="bg-darkbg outline-none w-full p-2 rounded "
            v-model="updatePassword.password"
            @blur="$v.updatePassword.password.$touch()"
          />
          <div v-if="$v.updatePassword.password.$error">
            <p
              class="text-xs text-red-500"
              v-if="!$v.updatePassword.password.required"
              id="error_label"
            >
              New password is required!
            </p>
          </div>
          <div v-if="$v.updatePassword.password.$error">
            <p
              class="text-xs text-red-600"
              v-if="!$v.updatePassword.password.minLength"
              id="error_label"
            >
              Password must be at least 8 characters!
            </p>
          </div>
        </div>
        <div class="">
          <BaseInput
            :passwordInput="true"
            autocomplete="off"
            label="Confirm Password"
            inputClass="bg-darkbg outline-none w-full p-2 rounded "
            v-model="updatePassword.passwordConfirm"
            @blur="$v.updatePassword.passwordConfirm.$touch()"
          />
          <div v-if="$v.updatePassword.passwordConfirm.$error">
            <p
              class="text-xs text-red-500"
              v-if="!$v.updatePassword.passwordConfirm.sameAs"
              id="error_label"
            >
              New Passwords don't match!
            </p>
          </div>
        </div>
        <div class="w-full">
          <BaseButton
            buttonClass="w-full"
            @click="updatePasswordFun()"
            class="w-full cursor-pointer flex text-base justify-center mt-2 p-2 bg-text"
            >Update Password</BaseButton
          >
        </div>
      </form>
    </div>
    <div class="p-2 w-full">
      <form
        @submit.prevent="updatePasswordFun"
        class="bg-darkcard p-1 px-2 w-full"
      >
        <div>Contact for Development Team</div>

        <div>
          <textarea
            rows="3"
            cols="50"
            class="w-full bg-darkbg text-sm px-5"
            v-model="message"
          ></textarea>
        </div>
        <div class="w-full">
          <BaseButton
            buttonClass="w-full"
            @click="sendForDevTeam()"
            class="w-full cursor-pointer flex text-base justify-center mt-2 p-2 bg-text"
            >Send Message</BaseButton
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      error: false,
      show: false,
      showOld: false,
      showConfirm: false,
      error2: false,
      status: false,
      updateProfile: {
        email: "",
        agentName: "",
        phoneNumber: "",
      },
      updatePassword: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      message: "",
    };
  },
  validations: {
    updateProfile: {
      email: { required, email },
      agentName: { required },
      phoneNumber: { required },
    },
    updatePassword: {
      oldPassword: { required },
      passwordConfirm: { required, passwordConfirm: sameAs("password") },
      password: { required, minLength: minLength(8) },
    },
  },
  created() {
    let user = localStorage.getItem("user");
    this.user = JSON.parse(user);
    this.populate();
  },
  methods: {
    populate() {
      this.updateProfile.agentName = this.user.agentName;
      this.updateProfile.email = this.user.email;
      this.updateProfile.phoneNumber = this.user.phoneNumber;
    },
    updateProfileFun() {
      this.$v.updateProfile.$touch();
      if (!this.$v.updateProfile.$invalid) {
        this.$store.dispatch(
          "Authentication/updateMyProfile",
          this.updateProfile
        );
      } else {
        // console.log("error");
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
    sendForDevTeam() {
      if (this.message !== "") {
        this.$store
          .dispatch("Authentication/sendForDevTeam", this.message)
          .then((response) => {
            if (response) {
              this.message = "";
            }
          });
      } else {
        const alert = {
          type: "error",
          message: "Please Write your message!",
        };
        this.$store.dispatch("notification/add", alert);
      }

      // console.log("this ", this.message);
    },
    updatePasswordFun() {
      this.$v.updatePassword.$touch();
      if (!this.$v.updatePassword.$invalid) {
        this.$store
          .dispatch("Authentication/updateMyPassword", this.updatePassword)
          .then((response) => {});
      } else {
        console.log("error");
        // this.error2 = true;
        setTimeout(() => {
          // this.error2 = false;
        }, 5000);
      }
    },
  },
};
</script>

<style></style>
