<template>
  <div>
    <div class="flex justify-center py-4" v-if="noConnection">
      <network-issue />
    </div>
    <div class="flex justify-center py-16" v-if="spinner"><spinner2 /></div>
    <div
      v-if="!spinner && !noConnection"
      class="bg-darkcard mx-4 my-1 text-gray-300 rounded-md p-3"
    >
      <div class="grid grid-cols-8 text-base">
        <div class="col-span-7 text-sm">Report History</div>
        <!-- <div v-if="Transaction.length === 0" class="col-span-7 text-sm">
          Not available | Transaction History
        </div> -->
        <transition name="fade" appear="">
          <div
            class="modal-overlay"
            v-if="reportModalStatus"
            @click="modal"
          ></div>
        </transition>

        <transition name="modal" appear="">
          <div
            class="moodal animate__animated animate__zoomIn animate__faster"
            style=""
            v-if="reportModalStatus"
          >
            <div class="rounded-lg overflow-hidden h-full" style="">
              <div class="h-full">
                <ReportModal />
              </div>
            </div>
          </div>
        </transition>
        <!-- <div>
          <button
            @click="$router.go(-1)"
            class="bg-text px-2 rounded-md text-xs py-2"
          >
            Go back
          </button>
        </div> -->
      </div>
      <div class="bg-darkbg my-2 rounded-xl">
        <table class="table-auto w-full text-sm overflow-x-auto">
          <!-- {{
          employees
        }} -->
          <thead>
            <tr class="space-x-8 text-left font-light">
              <th class="border-b border-b-gray-700 px-1 font-light py-2">#</th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Report Week
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Report Length
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Report Date
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Income
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Payout
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Profit
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Report Status
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Details
              </th>
              <!-- <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Transaction Amount
              </th> -->
            </tr>
          </thead>
          <!-- {{
          Transaction.employee
        }} -->
          <tbody :class="`h-${tableBodyHeight}`">
            <!-- <tr
              v-for="(tran, index) in separate"
              :key="index"
              class="text-gray-400"
            >
              <td class="px-1 py-2">{{ index + 1 }}</td>
              <td class="px-1 py-2">{{ tran.day }}</td>
              <td class="px-1 py-2">{{ tran.date }}</td>
              <td class="px-1 py-2">{{ tran.timeTable }}</td>
         
              <td class="px-1 py-2 text-yellow-300">
                {{ tran.cash.toLocaleString() }}
              </td>
              <td class="px-1 py-2 text-redo">
                {{ tran.totalPayoutMoney.toLocaleString() }}
              </td>
              <td class="px-1 py-2 text-blue-500">
                {{ (tran.cash - tran.totalPayoutMoney).toLocaleString() }}
              </td>
             
            </tr> -->
          </tbody>
        </table>
        <!-- <div
          class="text-3xl py-24 w-full text-center"
          v-if="Transaction.length == 0"
        >
          There are no Transaction History available!
        </div> -->
      </div>
      <!-- <div class="text-xs flex justify-end">
        <div class="flex space-x-5">
          <div class="flex space-x-8">
            <template v-if="page !== 1">
              <router-link :to="{ query: { page: page - 1 } }" rel="prev">
                ← Prev Page
              </router-link>
            </template>
            <div
              class="ml-2 text-sm cursor-pointer"
              :class="{
                'bg-text m w-8 rounded-md text-white text-center':
                  page === $route.query.page,
              }"
              @click="paginate(page)"
              v-for="(page, i) in letsTry"
              :key="i"
            >
              {{ page }}
            </div>
            <template v-if="page < letsTry.length">
              <router-link :to="{ query: { page: page + 1 } }" rel="prev"
                >Next Page →</router-link
              >
            </template>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import spinner2 from "@/components/spinner2.vue";
import NetworkIssue from "@/Error/NetworkIssue.vue";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    spinner2,
    NetworkIssue,
  },
  data() {
    return {
      noConnection: false,
      spinner: false,
      search: "",
      status: {},
      zero: true,
      name: "",
      tableBodyHeight: 0,
    };
  },
  created() {
    // this.spinner = true;
    // this.$store
    //   .dispatch(
    //     "branchEmployees/getEmployeeTransaction",
    //     this.$route.params.id
    //   )
    //   .then((response) => {
    //     if (response) {
    //       this.spinner = false;
    //       this.name = this.Transaction[0].employee.employeeName;
    //     } else {
    //       this.spinner = false;
    //       this.noConnection = true;
    //     }
    //   });
  },
  mounted() {
    const navbarHeight = 8; // Replace with your navbar height
    const tableHeaderHeight = 12; // Replace with your table header height
    const paginationHeight = 8; // Replace with your pagination height

    this.tableBodyHeight =
      window.innerHeight - navbarHeight - tableHeaderHeight - paginationHeight;
  },
  computed: {
    ...mapState("branchEmployees", ["Transaction"]),
    ...mapGetters("modals", ["reportModalStatus"]),
    letsTry() {
      let page = [];
      let pages = this.allTickets / 13;
      for (var i = 1; i < pages + 1; i++) {
        page.push(i);
      }
      return page;
    },

    separate() {
      let tmpArray = [];
      if (this.Transaction) {
        this.Transaction.forEach((i) => {
          const inputString = i.time;
          const holder = inputString.split(" ");
          let dateHolder = holder[0].split("-");
          let day = dateHolder[0];
          let date = dateHolder[1] + "-" + dateHolder[2] + "-" + dateHolder[3];
          let timeTable = holder[1] + " " + holder[2];
          // console.log("dateHolder: ", dateHolder);
          // console.log("day: ", day);
          // console.log("timeTable: ", timeTable);
          // console.log("holder: ", holder);
          tmpArray.push({
            ...i,
            day,
            date,
            timeTable,
          });
        });
      }
      return tmpArray;
    },
    page() {
      return parseInt(this.$route.query.page) || 1;
    },
    returnName() {
      let name = "";
      if (Transaction.length !== 0) {
        name = this.Transaction[0].employee.employeeName;
      }
      return name;
    },
  },
  methods: {
    Modal() {
      this.$store.dispatch("modals/modal", {
        stat: "reportModal",
        status: !this.reportModalStatus,
      });
    },
    paginate(selectedPage) {
      this.$router.push({ query: { page: selectedPage } });
      this.$store.dispatch("tickets/getAllTickets", {
        perPage: 13,
        page: this.page,
      });
    },
  },
};

// status and report exchange
// report = profit
// report check box exchange
</script>

<style></style>
