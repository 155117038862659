import Vue from "vue";
import store from "../store/store";
import VueRouter from "vue-router";

// Error
import NotFound from "../Error/NotFound.vue";
import NetworkIssue from "../Error/NetworkIssue.vue";
// Views
import ResetPassword from "@/components/Authentication/ResetPassword.vue";
import ResetPasswordByLink from "../components/Authentication/ResetPasswordByLink.vue";
// import ResetPassword from '../components/Authentication'
// import Keno from "../views/User/Keno.vue";
import Admin from "../views/Admin/Admin.vue";
import Login from "../components/Authentication/Login.vue";
import Config from "../views/Admin/Config.vue";
import Dashboard from "../views/Admin/Dashboard.vue";
import TicketDetails from "../views/Admin/TicketDetails.vue";

import ForgotPassword from "../components/Authentication/ForgetPassword.vue";
// import Cashier from "../views/Admin/Cashier.vue";
import Tickets from "../views/Admin/Tickets.vue";
import TransactionLog from "@/views/Admin/TransactionLog.vue";
import ReportHistory from "@/views/Admin/ReportHistory.vue";
// import Branches from "../views/Admin/Branches.vue";
import Employees from "../views/Admin/Employees.vue";

// import AdminKeno from "../views/Admin/KenoAdmin.vue";
const preventRoute = {
  beforeEnter: (to, from, next) => {
    if (localStorage.getItem("encryptedBranchToken")) {
      next();
    } else {
      localStorage.clear();
      next("/404");
    }
  },
};
const requireAuth = (to, from, next) => {
  const token = localStorage.getItem("encryptedBranchToken");
  if (token) {
    next("/Dashboard");
  } else {
    if (to.path === "/") {
      // check if current route is the same as destination route
      next();
    } else {
      next("/");
    }
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/404",
    name: "404",
    component: NotFound,
    meta: {
      title: "NotFound",
    },
  },
  {
    path: "/network-issue",
    name: "network-issue",
    component: NetworkIssue,
    meta: {
      title: "NetworkIssue",
    },
  },
  {
    path: "*",
    redirect: { name: "404" },
  },
  {
    path: "/ResetPassword",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      title: "ResetPassword",
    },
  },
  {
    path: "/ResetPasswordByLink/:id",
    name: "reset-password-by-link",
    component: ResetPasswordByLink,
    meta: {
      title: "ResetPasswordByLink",
    },
  },
  // {
  //   path: "/",
  //   name: "keno",
  //   component: Keno,
  //   meta: {
  //     title: "Keno",
  //   },
  // },
  {
    beforeEnter: requireAuth,
    path: "/",
    name: "log-in",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/forgotPassword",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      title: "ForgotPassword",
    },
  },
  {
    path: "/Admin",
    name: "admin",
    component: Admin,
    ...preventRoute,
    children: [
      {
        path: "/Dashboard",
        name: "dashboard",
        component: Dashboard,
        ...preventRoute,
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "/Tickets",
        name: "tickets",
        component: Tickets,
        ...preventRoute,
        meta: {
          title: "Tickets",
        },
      },
      {
        path: "/ticketDetails/:id",
        name: "ticket-details",
        component: TicketDetails,
        ...preventRoute,
        meta: {
          title: "Tickets",
        },
      },
      {
        path: "/ProfileSetting",
        name: "profile-setting",
        component: Config,
        ...preventRoute,
        meta: {
          title: "Config",
        },
      },
      {
        path: "/Employees",
        name: "employees",
        component: Employees,
        ...preventRoute,
        meta: {
          title: "Employees",
        },
      },
      {
        path: "/ReportHistory",
        name: "report-history",
        component: ReportHistory,
        ...preventRoute,
        meta: {
          title: "Report History",
        },
      },
      {
        path: "/TransactionLog/:id",
        name: "transaction-log",
        component: TransactionLog,
        ...preventRoute,
        meta: {
          title: "TransactionLog",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: () => {
    return {
      x: 0,
      y: 0,
    };
  },
});
router.beforeEach((to, from, next) => {
  document.title = `Branch | ${to.meta.title}`;
  next();
});

export default router;
