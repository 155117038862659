<template>
  <div class="NotificationContainer">
    <transition-group name="slide">
      <notification-bar
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
        class="slide-notification"
      />
    </transition-group>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NotificationBar from "./NotificationBar.vue";
export default {
  components: { NotificationBar },
  computed: mapState("notification", ["notifications"]),
};
</script>

<style scoped>
.NotificationContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-right: 20px;
  padding-bottom: 20px;
}
.slide-notification-enter-active,
.slide-notification-leave-active {
  transition: transform 0.2s ease;
}

.slide-notification-enter,
.slide-notification-leave-to {
  transform: translateX(100%);
}
</style>
