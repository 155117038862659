<template>
  <div id="app" class="bg-darkbg h-screen">
    <transition name="slide">
      <notification-container class="z-50" />
    </transition>
    <router-view />
  </div>
</template>
<script>
import NotificationContainer from "./components/Notifications/NotificationContainer.vue";
export default {
  components: { NotificationContainer },
};
</script>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
