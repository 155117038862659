import XLSX from "xlsx";
import { utils, write } from "xlsx";

export const exportToExcel = (tableElement) => {
  const table = tableElement;

  // Create a new table with the header row and data rows
  const exportTable = document.createElement("table");
  const headerRow = table.querySelector("thead tr");

  exportTable.appendChild(headerRow.cloneNode(true)); // Clone the header row

  const rows = table.querySelectorAll("tbody tr");
  rows.forEach((row) => {
    exportTable.appendChild(row.cloneNode(true)); // Clone the data rows
  });

  // Remove locale string formatting and set cell format to "Text" for number cells
  const cells = exportTable.querySelectorAll("td");
  cells.forEach((cell) => {
    const cellText = cell.textContent.trim();
    if (!isNaN(cellText)) {
      const number = Number(cellText.replace(/,/g, ""));
      cell.textContent = number;
      cell.setAttribute("style", "mso-number-format:'';");
    }
  });

  // Create a new Excel file and download it
  const wb = utils.table_to_book(exportTable, { sheet: "Sheet1" });
  const wbout = write(wb, {
    bookType: "xlsx",
    bookSST: true,
    type: "array",
  });

  const file = new Blob([wbout], { type: "application/octet-stream" });
  const fileName = "table_data.xlsx";
  const link = document.createElement("a");
  link.href = URL.createObjectURL(file);
  link.download = fileName;
  link.click();
};
