<template>
  <div>
    <div class="flex justify-center py-4" v-if="noConnection">
      <network-issue />
    </div>
    <div class="flex justify-center py-16" v-if="spinner"><spinner2 /></div>
    <div
      v-if="!spinner && !noConnection"
      class="bg-darkcard mx-4 my-1 text-gray-300 rounded-md p-3"
    >
      <div class="grid grid-cols-8 text-base">
        <div v-if="Ticket.length !== 0" class="col-span-7 text-sm">
          Ticket Details | {{ Ticket.customId }}
        </div>
        <div v-if="Ticket.length === 0" class="col-span-7 text-sm">
          Not available | Ticket History
        </div>
        <div>
          <button
            @click="$router.go(-1)"
            class="bg-text px-2 rounded-md text-xs py-2"
          >
            Go back
          </button>
        </div>
      </div>
      <div class="bg-darkbg my-2 rounded-xl overflow-x-auto">
        <table class="table-auto w-full text-sm overflow-x-auto">
          <!-- {{
          employees
        }} -->
          <thead>
            <tr class="space-x-8 text-left font-light">
              <!-- <th class="border-b border-b-gray-700 px-1 font-light py-2">#</th> -->
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Ticket Id
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Created At
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Cashier
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Paid Status
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Placed Money
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total odds
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Total Prize Money
              </th>
              <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Took Prize Money
              </th>
              <!-- <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Games
              </th> -->
            </tr>
          </thead>
          <tbody>
            <tr class="text-gray-400 p-2">
              <td class="px-1 py-2">{{ Ticket.customId }}</td>
              <td class="px-1 py-2">{{ Ticket.placedTime }}</td>
              <td class="px-1 py-2">{{ Ticket.placedCashier.employeeName }}</td>
              <td class="px-1 py-2">{{ Ticket.paidStatus }}</td>
              <td class="px-1 py-2">{{ Ticket.totalPlacedMoney }} birr</td>
              <td class="px-1 py-2">{{ Ticket.totalOdd }}</td>
              <td class="px-1 py-2 text-redo">
                <div v-if="Ticket.gameId">
                  <p v-if="Ticket.gameId.status !== 'pending'">
                    {{ Ticket.totalPrizeMoney }}
                  </p>

                  <p
                    class="bg-yellow-500 text-black w-1/2 font-bold"
                    v-if="Ticket.gameId.status == 'pending'"
                  >
                    Pending
                  </p>
                </div>
                <div v-if="!Ticket.gameId">
                  <p>
                    {{ Ticket.totalPrizeMoney }}
                  </p>
                </div>
              </td>
              <td class="px-1 py-2 text-blue-500">
                <p v-if="Ticket.tookPrizeMoney == true">Yes</p>
                <p v-if="Ticket.tookPrizeMoney == false">No</p>
                <!-- <p class="bg-yellow-500" v-if="Ticket.gameId.status == 'pending'">
                  Pending
                </p> -->
              </td>
              <!-- <td @click="Modal()" class="px-1 py-2 text-blue-500">More</td> -->
            </tr>
          </tbody>
        </table>
      </div>

      <div class="">
        <div>
          <p>Drawn Numbers:</p>
          <div v-if="Ticket.gameId">
            <div
              class="space-x-2 lg:flex grid grid-cols-10 bg-darkbg p-5 w-fit"
            >
              <div
                v-if="Ticket.gameId.status !== 'pending'"
                class="space-x-4 w-full ml-3 text-base"
                v-for="(i, index) in DrawnNumber.numbers_That_Won"
                :key="index"
              >
                <p
                  :class="{
                    'text-green-500': returnSelectedSpots.includes(i),
                    'text-white': !returnSelectedSpots.includes(i),
                  }"
                >
                  {{ i }},
                </p>
              </div>
            </div>
            <div
              v-if="Ticket.gameId.status == 'pending'"
              class="flex space-x-2 bg-darkbg p-5 w-fit"
            >
              <div
                class="flex bg-yellow-500 text-black space-x-4 w-full ml-3 text-base"
              >
                Game is Pending
              </div>
            </div>
          </div>
          <!-- <div v-if="!Ticket.gameId">
            <div class="flex space-x-2 bg-darkbg p-5 w-fit">
             
              ---------
            </div>
          </div> -->
        </div>

        <div class="w-full" v-if="Ticket.gameId">
          <p>Tickets</p>
          <div class="lg:grid lg:grid-cols-2 lg:gap-3 space-y-3 lg:space-x-0">
            <div
              class="bg-darkbg p-2"
              v-for="(i, index) in returnGames"
              :key="index"
            >
              <div class="flex justify-start space-x-2">
                <p>Spots</p>
                <div
                  class=""
                  v-for="(j, index) in i.selectedNumbers"
                  :key="index"
                >
                  <div>
                    <div v-if="DrawnNumber">
                      <p
                        v-if="Num"
                        :class="{
                          'text-green-500':
                            DrawnNumber.numbers_That_Won.includes(j.number),
                        }"
                      >
                        {{ j.number }},
                      </p>
                    </div>

                    <p v-if="!Num">{{ j.number }},</p>
                  </div>
                </div>
              </div>
              <br class="lg:hidden" />
              <div class="md:text-base text-sm lg:flex justify-between">
                <div>Price: {{ i.placedMoney }}</div>
                <div>
                  Possible Payout:
                  {{ i.possiblePayout }}
                </div>
                <div class="flex space-x-2">
                  <p>Prize Money:</p>
                  <p
                    class="text-blue-500"
                    v-if="Ticket.gameId.status !== 'pending'"
                    :class="{ 'text-red-500': i.prizeMoney > 0 }"
                  >
                    {{ i.prizeMoney }}
                  </p>
                  <!-- <p
                    class="text-blue-500"
                    v-if="Ticket.gameId.status !== 'pending'"
                    :class="{ 'text-green-500': i.prizeMoney == 0 }"
                  >
                    {{ i.prizeMoney }}
                  </p> -->
                  <p
                    class="bg-yellow-500 text-black"
                    v-if="Ticket.gameId.status == 'pending'"
                  >
                    Pending
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full" v-if="!Ticket.gameId">
          <p>Tickets</p>
          <div class="lg:grid grid-cols-2 gap-3">
            {{ returnSelectedSpots }}
            <div
              class="bg-darkbg p-2"
              v-for="(i, index) in returnGames"
              :key="index"
            >
              <div class="flex justify-start space-x-2">
                <p>Spots</p>
                <div
                  class=""
                  v-for="(j, index) in i.selectedNumbers"
                  :key="index"
                >
                  <div>
                    <p>{{ j.number }},</p>
                  </div>
                </div>
              </div>
              <div class="flex justify-between">
                <div>Price: {{ i.placedMoney }}</div>
                <div>
                  Possible Payout:
                  {{ i.possiblePayout }}
                </div>
                <div class="flex space-x-2">
                  <p>Prize Money:</p>
                  <p
                    class="text-blue-500"
                    :class="{ 'text-red-500': i.prizeMoney > 0 }"
                  >
                    {{ i.prizeMoney }}
                  </p>
                  <!-- <p
                    class="text-blue-500"
                    v-if="Ticket.gameId.status !== 'pending'"
                    :class="{ 'text-green-500': i.prizeMoney == 0 }"
                  >
                    {{ i.prizeMoney }}
                  </p> -->
                  <!-- <p
                    class="bg-yellow-500 text-black"
                    v-if="Ticket.gameId.status == 'pending'"
                  >
                    Pending
                  </p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import spinner2 from "@/components/spinner2.vue";
import NetworkIssue from "@/Error/NetworkIssue.vue";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    spinner2,
    NetworkIssue,
  },
  data() {
    return {
      noConnection: false,
      spinner: false,
      DrawnNumber: [],
      selectedSpotsList: [],
      Ticket: [],
      game: [],
      Num: false,
    };
  },
  created() {
    // this.Ticket = this.$route.params.data;
    this.spinner = true;
    // console.log(" this.$route.params.id: ", this.$route.params.id);
    this.$store
      .dispatch("tickets/searchTicket", this.$route.params.id)
      .then((response) => {
        if (response) {
          this.Ticket = response[0];
          if (response && response[0].gameId) {
            this.$store
              .dispatch("tickets/searchGame", response[0].gameId.customId)
              .then((res) => {
                if (res && response[0].gameId.status == "finished") {
                  this.Num = true;
                  this.DrawnNumber = res;
                  // console.log("res: ", res);
                  // console.log("Drawn: ", res);
                  this.spinner = false;
                } else {
                  this.spinner = false;
                }
              });
          } else {
            this.spinner = false;
          }
        } else {
          this.spinner = false;
          this.noConnection = true;
        }
      })
      .catch((error) => {
        this.spinner = false;
        this.noConnection = false;
      });
  },
  computed: {
    ...mapGetters("modals", ["reportModalStatus"]),
    returnGames() {
      return this.Ticket.game;
    },
    returnNumbers() {
      return this.ticket.game;
    },
    returnSelectedSpots() {
      if (this.Ticket) {
        this.selectedSpotsList = [];
        this.Ticket.game.forEach((element) => {
          let num = element.selectedNumbers;
          num.forEach((el) => {
            let result = Number(el.number);
            this.selectedSpotsList.push(result);
          });
        });
      }
      return this.selectedSpotsList;
    },
  },
  methods: {
    Modal() {
      this.$store.dispatch("modals/modal", {
        stat: "addEmployee",
        status: !this.gameDetails,
      });
    },
  },
};

// status and report exchange
// report = profit
// report check box exchange
</script>

<style>
.moodal {
  position: fixed;
  /* top: 48%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  z-index: 99;
  /* width: 80%; */
  /* max-height: fit-content; */
  /* max-width: 100%;
  min-width: 56%;
  min-height: 50%; */
  /* background-color: #1c1c1e; */
}
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal-enter-active,
.modal-leave-active {
  transition: all 0.5s ease;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
  transform: scale(0.5);
}
/* .modal-overlay-enter-active,
.modal-overlay-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-overlay-leave-to {
  opacity: 0;
}

.modal-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}
.modal-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-enter-from {
  opacity: 0;
  transform: scale(0.8);
}
.modal-leave-from {
  transform: scale(0.8);
} */
</style>
