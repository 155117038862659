<template>
  <div class="text-xs">
    <div class="flex justify-start">
      <div>
        <template v-if="page !== 1">
          <div
            class="cursor-pointer text-base w-16"
            @click="paginate(page - 1)"
            rel="prev"
          >
            ← Prev |
          </div>
        </template>
      </div>

      <div class="lg:grid lg:grid-cols-30 grid grid-cols-8">
        <div
          class="grid grid-cols-12 text-sm cursor-pointer"
          @click="paginate(currentPage)"
          v-for="(currentPage, i) in paginationLength"
          :key="i"
          :class="{
            'bg-5  rounded-md text-white text-center h-6': page == currentPage,
          }"
        >
          <p class="px-3">{{ currentPage }}</p>
        </div>
      </div>
      <div>
        <template v-if="page < paginationLength.length">
          <div
            @click="paginate(page + 1)"
            rel="prev"
            class="cursor-pointer text-base w-16"
          >
            | Next →
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    show: {
      default: 13,
      type: Number,
    },
    itemsCount: {
      required: true,
      type: Number,
    },
  },
  computed: {
    paginationLength() {
      let page = [];
      let pages = this.itemsCount / this.show;
      for (var i = 1; i < pages + 1; i++) {
        page.push(i);
      }
      return page;
    },
    page() {
      return parseInt(this.$route.query.page) || 1;
    },
  },
  methods: {
    paginate(selectedPage) {
      this.$parent.paginate(selectedPage);
    },
  },
};
</script>

<style></style>
