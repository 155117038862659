/* eslint-disable */
import Vue from "vue";
import CryptoJS from "crypto-js";
const key = "itain'tbeingalone";
import AxiosService from "../../services/AxiosServices";
import AuthenticationServices from "@/services/AuthenticationServices";
import router from "@/router";
export const namespaced = true;
export const state = {
  userInfo: [],
  userToken: null,
  userRole: "",
  nightMode: "",
  me: [],
};

export const mutations = {
  //Signin
  USER_TOKEN(state, token) {
    state.userToken = token;
  },
  USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
  },
};
export const actions = {
  logOut() {
    const routeToHome = () => {
      localStorage.removeItem("name");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("userRole");
      this.$router.push({
        name: "log-in",
      });
    };
    window.setTimeout(routeToHome, 1000);
  },
  //signup
  signUp({ commit, dispatch }, payload) {
    // console.log(payload)
    return AuthenticationServices.postSignup(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message:
            "Your account has been created!,please now login to continue",
        };
        dispatch("notification/add", notification, { root: true });
        return res;
      })
      .catch((error) => {
        ErrorHandler(error);
        // return false;
      });
  },
  updateMyProfile({ dispatch }, payload) {
    return AxiosService.updateMyProfile(payload)
      .then((res) => {
        if (res.data.status === "success") {
          let user = {
            id: res.data.user._id,
            agentName: res.data.user.agentName,
            employeeName: res.data.user.employeeName,
            email: res.data.user.email,
            role: res.data.user.role,
            pic: res.data.user.photos,
            phoneNumber: res.data.user.phoneNumber,
          };
          AxiosService.getMe().then((res) => {
            state.me = res.data.user;
          });
          // localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(user));
          // commit("USER_INFO", res.data.user);
          // commit("USER_TOKEN", res.data.token);
          state.nightMode = res.data.user.nightMode;
          // console.log("night Mode", state.nightMode);
          const notification = {
            type: "success",
            message: "You have signed in successfully",
          };
          dispatch("notification/add", notification, { root: true });
          return true;
        }
        const notification = {
          type: "success",
          message: "profile updated successfully!",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        ErrorHandler(error);
        // return false;
      });
  },
  updateMyPassword({ dispatch }, formdata) {
    // console.log("this", formdata);
    return AxiosService.updateMyPassword(formdata)
      .then((res) => {
        const notification = {
          type: "success",
          message: "Password updated successfully",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        ErrorHandler(error);
        // return false;
      });
  },
  sendForDevTeam({ dispatch }, formdata) {
    // console.log("this", formdata);
    return AxiosService.sendForDevTeam(formdata)
      .then((res) => {
        const notification = {
          type: "success",
          message: "Message sent successfully! Thankyou for your comment! ",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        ErrorHandler(error);
        // return false;
      });
  },
  getMe({ state }) {
    return AxiosService.getMe()
      .then((res) => {
        state.me = res.data.user;
        // console.log("user: ", res.data.user);
        return true;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  signIn({ state, commit, dispatch }, payload) {
    // console.log(payload)
    return AuthenticationServices.postLogin(payload)
      .then((res) => {
        // console.log("res status sts: ", res.data.status);
        // console.log("res  sts: ", res);
        if (res.data.status === "success") {
          let user = {
            id: res.data.user._id,
            agentName: res.data.user.agentName,
            employeeName: res.data.user.employeeName,
            email: res.data.user.email,
            role: res.data.user.role,
            pic: res.data.user.photos,
            phoneNumber: res.data.user.phoneNumber,
          };
          const encryptedToken = CryptoJS.AES.encrypt(
            res.data.token,
            key
          ).toString();
          localStorage.setItem("encryptedBranchToken", encryptedToken);
          // localStorage.setItem("encryptedBranchToken", res.data.token);
          localStorage.setItem("user", JSON.stringify(user));
          commit("USER_INFO", res.data.user);
          commit("USER_TOKEN", res.data.token);
          state.nightMode = res.data.user.nightMode;
          // console.log("night Mode", state.nightMode);
          const notification = {
            type: "success",
            message: "You have signed in successfully",
          };
          dispatch("notification/add", notification, { root: true });
          return true;
        } else if (res.data.status === "activate") {
          const notification = {
            type: "error",
            message: error.response.data.message,
          };
          dispatch("notification/add", notification, { root: true });
          return false;
        }
      })
      .catch((error) => {
        if (error.code == "ERR_BAD_REQUEST") {
          if (error.response.data.error.statusCode == 401) {
            localStorage.clear();
            // router.push("/");
            const notification = {
              type: "error",
              message: error.response.data.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          } else {
            const notification = {
              type: "error",
              message: error.response.data.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        } else {
          ErrorHandler(error);
          return false;
        }
      });
  },
  forgetPassword({ commit, dispatch }, payload) {
    // console.log(payload);
    return AuthenticationServices.forgetPassword(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message:
            "we have sent password reset code Please check your email account!",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  ResetPassword({ commit, dispatch }, payload) {
    // console.log(payload);
    return AuthenticationServices.ResetPassword(payload)
      .then((res) => {
        // console.log("res status sts: ", res.data.status);
        // console.log("res  sts: ", res);
        if (res.data.status === "success") {
          localStorage.clear();
          let user = {
            id: res.data.user._id,
            employeeName: res.data.user.employeeName,
            email: res.data.user.email,
            role: res.data.user.role,
            pic: res.data.user.photos,
            phoneNumber: res.data.user.phoneNumber,
          };
          // localStorage.setItem("encryptedBranchToken", res.data.token);
          const encryptedToken = CryptoJS.AES.encrypt(
            res.data.token,
            key
          ).toString();
          localStorage.setItem("encryptedBranchToken", encryptedToken);
          localStorage.setItem("user", JSON.stringify(user));
          commit("USER_INFO", res.data.user);
          commit("USER_TOKEN", res.data.token);
          const notification = {
            type: "success",
            message:
              "you have successfully updated your password and logged in!",
          };
          dispatch("notification/add", notification, { root: true });
          return true;
        } else if (res.data.status === "activate") {
          const notification = {
            type: "error",
            message: error.response.data.message,
          };
          dispatch("notification/add", notification, { root: true });
          return false;
        }
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  ResetPasswordByLink({ commit, dispatch }, { token, formdata }) {
    return AuthenticationServices.ResetPasswordByLink({ token, formdata })
      .then((res) => {
        // console.log("res status sts: ", res.data.status);
        // console.log("res  sts: ", res);
        if (res.data.status === "success") {
          localStorage.clear();
          let user = {
            id: res.data.user._id,
            employeeName: res.data.user.employeeName,
            email: res.data.user.email,
            role: res.data.user.role,
            pic: res.data.user.photos,
            phoneNumber: res.data.user.phoneNumber,
          };
          const encryptedToken = CryptoJS.AES.encrypt(
            res.data.token,
            key
          ).toString();
          localStorage.setItem("encryptedBranchToken", encryptedToken);
          // localStorage.setItem("encryptedBranchToken", res.data.token);
          localStorage.setItem("user", JSON.stringify(user));
          commit("USER_INFO", res.data.user);
          commit("USER_TOKEN", res.data.token);
          const notification = {
            type: "success",
            message:
              "you have successfully rested your password and logged in!",
          };
          dispatch("notification/add", notification, { root: true });
          return true;
        } else if (res.data.status === "activate") {
          const notification = {
            type: "error",
            message:
              "Your account is not active please contact your development team!!!",
          };
          dispatch("notification/add", notification, { root: true });
          return false;
        }
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  logOut({ dispatch }) {
    const notification = {
      type: "logout",
      message: "You have signed off successfully",
    };
    dispatch("notification/add", notification, { root: true });
  },
};
export const getters = {};

export default {};
