<template>
  <div id="Myfont" class="bg-darkbg h-screen">
    <AgentNavBar class="sticky top-0" />
    <RouterView />
  </div>
</template>
<script>
import AgentNavBar from "../../components/AgentNavBar.vue";
export default {
  components: {
    AgentNavBar,
  },
};
</script>

<style>
#Myfont {
  font-family: PTSans;
}
@font-face {
  font-family: "PTSans";
  src: local("PTSans"),
    url("../../assets/Fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 100;
  font-size: xx-large;
}
</style>
