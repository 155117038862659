<template>
  <div>
    <div class="flex justify-center py-4" v-if="noConnection">
      <network-issue />
    </div>

    <div
      v-if="!noConnection"
      class="bg-darkcard mx-4 my-2 text-gray-300 rounded-md p-2"
    >
      <BaseModal modalClass="md:w-3/4 w-11/12" :visible="gameDetailsStatus">
        <GameRecall class="w-full" :info="modalData"
      /></BaseModal>
      <div class="hidden lg:grid grid-cols-7 text-base">
        <div class="col-span-4">Tickets</div>
        <div class="flex space-x-3 col-span-3">
          <p class="text-base">Show</p>
          <div class="inline-block relative w-16">
            <BaseSelect
              class="space-x-3"
              @input="fetchTickets(id)"
              v-model="show"
              selectClass="w-full p-1 bg-darkbg text-base"
              optionClass="w-full space-x-3 font-thin text-lg"
              :options="returnShowLength"
            />
          </div>
          <p class="fa fa-filter text-yellow-500 fa-2x"></p>
          <div class="inline-block relative w-24">
            <BaseSelect
              class="space-x-3"
              @change="filterTickets($event)"
              v-model="filter"
              selectClass="w-full p-1 bg-darkbg text-base"
              optionClass="w-full space-x-3 font-thin text-lg"
              :options="returnFilterOptions"
            />
          </div>
          <BaseInput
            @keypress.enter="searchTicket()"
            autocomplete="off"
            placeholder="search ticket"
            type="Text"
            inputClass="px-2 bg-darkbg outline-none w-full p-1 rounded "
            v-model="search"
          />
          <BaseButton
            v-if="search"
            class="text-red-600"
            @click="getAllAfterSearch()"
            >cancel</BaseButton
          >
        </div>
      </div>
      <div class="lg:hidden text-base">
        <div class="flex justify-between">
          <div class="col-span-4">Tickets</div>
          <div class="flex">
            <BaseInput
              @keypress.enter="searchTicket()"
              autocomplete="off"
              placeholder="search ticket"
              type="Text"
              inputClass="px-2 bg-darkbg outline-none w-full p-1 rounded "
              v-model="search"
            />
            <BaseButton
              v-if="search"
              class="text-red-600 mt-2 fa fa-times"
              @click="getAllAfterSearch()"
            ></BaseButton>
          </div>
          <!-- <p @mouseover="calendar = true">
            {{ selectedDate | date }}
          </p>
          <div class="dropdown hover:block relative inline-block my-auto mx-4">
            <div
              class="dropdown-content hover:block hover:text-left hover:right-0 z-10 :hover hidden shadow-md px-3 py-4 absolute min-w-40 w-40 space-y-5 mr-5"
            >
              <date-picker
                v-if="calendar"
                @input="fetchTicketsByDate()"
                :isDark="true"
                v-model="selectedDate"
              ></date-picker>
            </div>
          </div> -->
        </div>

        <div class="flex space-x-3 w-full"></div>
      </div>
      <div class="bg-darkbg h-full my-2 rounded-xl overflow-x-auto">
        <table class="table-auto w-full text-sm">
          <thead class="sticky">
            <tr class="space-x-8 text-left font-light">
              <th class="border-b border-b-gray-700 px-4 font-light py-2">#</th>
              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                BetID
              </th>
              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Game Id
              </th>
              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Cashier
              </th>
              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Bet Money
              </th>
              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Total Odds
              </th>

              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Possible Payout
              </th>

              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Prize Money
              </th>
              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Payout
              </th>
              <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                More
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-if="tickets.length !== 0"
              v-for="(tick, index) in tickets"
              :key="index"
              class="text-gray-400"
            >
              <td class="px-4 py-2">{{ index + 1 }}</td>
              <td class="px-4 py-2">{{ tick.customId }}</td>
              <td class="px-4 py-2">
                <p v-if="tick.gameId">
                  {{ tick.gameId.customId }}
                </p>
                <p v-if="!tick.gameId">---</p>
              </td>
              <td class="px-4 py-2">
                <p v-if="tick.placedCashier">
                  {{ tick.placedCashier.employeeName }}
                </p>
                <p v-if="!tick.placedCashier">---</p>
              </td>

              <td class="px-4 py-2">
                {{ tick.totalPlacedMoney.toLocaleString() }} birr
              </td>
              <td class="px-4 py-2">{{ tick.totalOdd }}</td>
              <td class="px-4 py-2">
                {{ tick.maxPossiblePayout.toLocaleString() }} birr
              </td>

              <td class="px-4 py-2 text-green-500">
                <!-- <p
                  v-if="tick.gameId.status == 'pending'"
                  class="bg-yellow-500 text-black w-1/2"
                >
                  Pending
                </p> -->
                <p v-if="tick.totalPrizeMoney" class="text-red-500">
                  {{ tick.totalPrizeMoney.toLocaleString() }} birr
                </p>
                <!-- <p v-if="!tick.totalPrizeMoney" class="text-red-500">
                  {{ tick.totalPrizeMoney }} <p 
                  class="bg-yellow-500 text-black w-1/2"
                >
                  Pending
                </p> -->
                <!-- </p> -->
                <p class="text-green-500" v-if="tick.totalPrizeMoney == 0">0</p>
                <!-- <p class="text-red-500" v-if="!tick.totalPrizeMoney">Pending</p> -->
              </td>
              <td class="px-4 text-right flex space-x-2 py-2">
                <img
                  v-if="tick.tookPrizeMoney == true"
                  class="w-4 h-4"
                  src="../../assets/icons/paidout.svg"
                  alt=""
                  srcset=""
                />
                <p v-if="tick.tookPrizeMoney == true">Yes</p>
                <p v-if="tick.tookPrizeMoney == false">No</p>
              </td>
              <td class="px-4 py-2" @click="TicketDetails(tick)">More</td>
            </tr>
          </tbody>
        </table>
        <div
          class="text-3xl py-24 w-full text-center"
          v-if="tickets.length === 0 && !status"
        >
          There are no tickets available!
        </div>
      </div>
      <BasePagination
        v-if="allTickets > 13 && tickets.length !== 0 && tickets.length !== 1"
        :show="this.show"
        :itemsCount="this.allTickets"
      />
    </div>
  </div>
</template>

<script>
import BasePagination from "@/components/base/BasePagination.vue";
import { mapState, mapGetters } from "vuex";
import GameRecall from "@/components/Modal/GameRecall.vue";
import NetworkIssue from "@/Error/NetworkIssue.vue";
export default {
  components: {
    NetworkIssue,
    GameRecall,
    BasePagination,
  },
  data() {
    return {
      status: false,
      modalData: [],
      selectedDate: "",
      filter: "All",
      calendar: false,
      noConnection: false,
      show: 13,
      search: "",
      tickets: [],
      watchedTickets: [],
    };
  },
  created() {
    this.selectedDate = new Date();
    this.fetchTickets();

    this.$store.dispatch("tickets/getCountAllTickets");
  },
  computed: {
    page() {
      return parseInt(this.$route.query.page) || 1;
    },
    ...mapGetters("modals", ["gameDetailsStatus"]),
    ...mapState("tickets", ["allTickets"]),
    returnShowLength() {
      let length = [10, 11, 13, 15, 17, 20];
      return length;
    },
    returnFilterOptions() {
      let length = ["All", "Won", "Pending", "Lost"];
      return length;
    },
  },
  methods: {
    fetchTickets() {
      this.status = true;
      this.$store
        .dispatch("tickets/getAllTickets", {
          perPage: this.show,
          page: this.page,
        })
        .then((response) => {
          // console.log("response:", response);
          if (response) {
            this.noConnection = false;
            this.status = false;
            this.tickets = response;
          } else {
            this.noConnection = true;
          }
        })
        .catch((err) => {
          this.noConnection = true;
        });
    },
    TicketDetails(tick) {
      this.modalData = tick;
      this.modal();
    },
    fetchTicketsByDate() {
      this.calendar = false;
    },
    paginate(selectedPage) {
      this.$router.push({ query: { page: selectedPage } });
      this.$store
        .dispatch("tickets/getAllTickets", {
          perPage: this.show,
          page: this.page,
        })
        .then((response) => {
          if (response) {
            this.tickets = response;
          } else {
            this.networkIssue = true;
          }
        });
    },
    searchTicket() {
      this.$store
        .dispatch("tickets/searchTicket", this.search)
        .then((response) => {
          if (response) {
            this.tickets = response;
            // console.log("tickets: ", this.tickets);
          }
        });
    },
    getAllAfterSearch() {
      this.search = "";
      this.$store
        .dispatch("tickets/getAllTickets", {
          perPage: this.show,
          page: this.page,
        })
        .then((response) => {
          if (response) {
            this.tickets = response;
          }
        });
    },
    modal() {
      this.$store.dispatch("modals/modal", {
        stat: "gameDetails",
        status: !this.gameDetailsStatus,
      });
    },
  },
};
</script>

<style>
.dropdown:hover .dropdown-content {
  display: block;
  right: 0%;
  text-align: left;
}
</style>
