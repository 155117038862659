<template>
  <div>
    <button
      type="button"
      v-on="$listeners"
      v-bind="$attrs"
      :class="buttonClass"
    >
      <i v-if="icon" class="pr-2" :class="icon"></i>
      <!-- <BaseSpinner v-if="spinner" /> -->
      <!-- <slot v-if="!spin"> </slot> -->
      <slot> </slot>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      spin: false,
    };
  },
  inheritAttrs: false,
  props: {
    buttonClass: {
      type: String,
    },
    spinner: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
  },
  methods: {
    // startSpinner() {
    //   if (spinner) {
    //     this.spin = true;
    //   }
    // },
  },
};
</script>

<style scoped></style>
