var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.noConnection)?_c('div',{staticClass:"flex justify-center py-4"},[_c('network-issue')],1):_vm._e(),(!_vm.noConnection)?_c('div',{staticClass:"p-5 text-white flex w-full"},[_c('div',{staticClass:"space-y-5 w-full"},[_c('div',{staticClass:"h-auto bg-darkcard rounded-lg p-4"},[_c('p',{staticClass:"text-white"},[_vm._v("Today's Report")]),_c('div',{staticClass:"hidden lg:block"},[_c('apexchart',{staticClass:"text-text",staticStyle:{"color":"red"},attrs:{"width":"100%","height":"350","type":"area","options":_vm.options,"series":_vm.series}})],1)]),_c('div',{staticClass:"text-center w-full space-y-5 lg:hidden p-1 text-sm rounded-lg bg-darkcard"},[_c('p',{staticClass:"w-full"},[_vm._v("Individual Cashier Report")]),_c('div',[_c('div',{staticClass:"p-3 space-y-3"},_vm._l((_vm.cashierReport),function(i,index){return _c('div',{key:index,staticClass:"bg-darkbg space-y-2 text-base rounded-lg p-3 w-full text-center"},[_c('div',[_vm._v(_vm._s(i.employeeName))]),_c('div',{staticClass:"text-white text-left text-base space-y-3"},[_c('div',{staticClass:"flex space-x-3"},[_c('div',[_vm._v("Total Gross income:")]),_c('div',{staticClass:"text-yellow-500"},[_c('div',[_vm._v(" "+_vm._s(i.totalGrossMoney.toLocaleString())+" ")])])]),_c('div',{staticClass:"flex space-x-3"},[_c('div',[_vm._v("Total Payouts:")]),_c('div',{staticClass:"text-red-500"},[_c('div',[_vm._v(" "+_vm._s(i.totalPayouts.toLocaleString())+" ")])])]),_c('div',{staticClass:"flex space-x-3"},[_c('div',{},[_vm._v("Total Profit:")]),_c('div',[_c('div',[_c('p',{class:{
                          'text-redo': i.totalProfit < 0,
                          'text-green-500': i.totalProfit >= 0,
                        }},[_vm._v(" "+_vm._s(i.totalProfit.toLocaleString())+" ")])])])])])])}),0)])]),_c('div',{staticClass:"text-center w-full space-y-5 lg:space-y-0 lg:flex lg:justify-around justify-center p-5 text-sm rounded-lg bg-darkcard"},[_c('p',{staticClass:"lg:hidden text-xl"},[_vm._v("Total Report")]),_c('div',{staticClass:"lg:block flex justify-center"},[_c('div',{staticClass:"bg-darkbg space-y-2 text-base rounded-lg p-3 w-56 text-center"},[_c('div',[_vm._v("Total Tickets Sold")]),_c('div',{staticClass:"text-indigo-500 space-x-3 text-center text-xl"},[_c('div',[_vm._v(_vm._s(_vm.animateTotalTickets.toLocaleString()))])])])]),_c('div',{staticClass:"lg:block flex justify-center"},[_c('div',{staticClass:"bg-darkbg space-y-2 text-base rounded-lg p-3 w-56 text-center"},[_c('div',[_vm._v("Total Gross income")]),_c('div',{staticClass:"text-yellow-500 space-x-3 text-center text-xl"},[_c('div',[_vm._v(" "+_vm._s(_vm.animateTotalGrossIncome.toLocaleString())+" ")])])])]),_c('div',{staticClass:"lg:block flex justify-center"},[_c('div',{staticClass:"bg-darkbg space-y-2 text-base rounded-lg p-3 w-56 text-center"},[_c('div',[_vm._v("Total Payouts")]),_c('div',{staticClass:"text-red-400 space-x-3 text-center text-xl"},[_c('div',[_vm._v(_vm._s(_vm.animateTotalPayouts.toLocaleString()))])])])]),_c('div',{staticClass:"lg:block flex justify-center"},[_c('div',{staticClass:"bg-darkbg space-y-2 text-base rounded-lg p-3 w-56 text-center"},[_c('div',[_vm._v("Total Profit")]),_c('div',{staticClass:"text-green-400 space-x-3 text-center text-xl"},[_c('div',[_c('p',{class:{
                    'text-redo': _vm.animateTotalProfit < 0,
                    'text-green-500': _vm.animateTotalProfit >= 0,
                  }},[_vm._v(" "+_vm._s(_vm.animateTotalProfit.toLocaleString())+" ")])])])])])])]),_c('div',{staticClass:"object-contain"})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }