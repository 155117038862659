<template>
  <div id="Myfont" class="bg-darkcard w-full text-white overflow-y-auto">
    <div class="flex justify-between w-full lg:px-8 px-2 py-4">
      <div v-if="Ticket.length !== 0" class="col-span-7 text-sm">
        Ticket Details | {{ Ticket.customId }}
      </div>
      <img
        @click="modal()"
        class="cursor-pointer"
        src="../../assets/icons/x.svg"
        alt=""
        srcset=""
      />
    </div>
    <div>
      <div class="bg-darkcard mx-4 my-1 text-gray-300">
        <div class="bg-darkbg my-2 overflow-x-auto">
          <table class="table-auto w-full text-sm overflow-x-auto">
            <!-- {{
          employees
        }} -->
            <thead>
              <tr class="space-x-8 text-left font-light">
                <!-- <th class="border-b border-b-gray-700 px-1 font-light py-2">#</th> -->
                <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                  Ticket Id
                </th>
                <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                  Created At
                </th>
                <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                  Cashier
                </th>
                <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                  Paid Status
                </th>
                <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                  Total Placed Money
                </th>
                <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                  Total odds
                </th>
                <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                  Total Prize Money
                </th>
                <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                  Took Prize Money
                </th>
                <!-- <th class="px-1 border-b border-b-gray-700 py-2 font-light">
                Games
              </th> -->
              </tr>
            </thead>
            <tbody>
              <tr class="text-gray-400 p-2">
                <td class="px-1 py-2">{{ Ticket.customId }}</td>
                <td class="px-1 py-2">{{ Ticket.placedTime }}</td>
                <td class="px-1 py-2">
                  {{ Ticket.placedCashier.employeeName }}
                </td>
                <td class="px-1 py-2">{{ Ticket.paidStatus }}</td>
                <td class="px-1 py-2">{{ Ticket.totalPlacedMoney }} birr</td>
                <td class="px-1 py-2">{{ Ticket.totalOdd }}</td>
                <td class="px-1 py-2">
                  <div v-if="Ticket.gameId">
                    <p class="" v-if="Ticket.gameId.status !== 'pending'">
                      {{ Ticket.totalPrizeMoney }} birr
                    </p>

                    <p
                      class="bg-yellow-500 text-black w-1/2 font-bold"
                      v-if="Ticket.gameId.status == 'pending'"
                    >
                      Pending
                    </p>
                  </div>
                  <div v-if="!Ticket.gameId">
                    <p>{{ Ticket.totalPrizeMoney }} birr</p>
                  </div>
                </td>
                <td class="px-1 py-2 text-blue-500">
                  <p class="" v-if="Ticket.tookPrizeMoney == true">Yes</p>
                  <p class="" v-if="Ticket.tookPrizeMoney == false">No</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="">
          <div>
            <p>Drawn Numbers:</p>
            <div
              class="w-1/2 bg-darkbg p-3 flex justify-center"
              v-if="gameRequestSent"
            >
              <base-spinner />
            </div>

            <div v-if="DrawnNumber.numbers_That_Won && !gameRequestSent">
              <div
                class="space-x-2 lg:flex grid grid-cols-10 bg-darkbg md:p-3 p-2 w-fit"
              >
                <div
                  v-if="Ticket.gameId.status !== 'pending'"
                  class="lg:space-x-4 space-x-0 text-left text-sm w-full lg:ml-3 md:text-base"
                  v-for="(i, index) in DrawnNumber.numbers_That_Won"
                  :key="index"
                >
                  <p
                    :class="{
                      'text-green-500 ml-2': returnSelectedSpots.includes(i),
                      'text-white ml-2': !returnSelectedSpots.includes(i),
                    }"
                  >
                    {{ i }},
                  </p>
                </div>
              </div>
            </div>
            <div
              v-if="!DrawnNumber.numbers_That_Won && !gameRequestSent"
              class="flex justify-center space-x-2 bg-darkbg p-2 w-1/2"
            >
              <div
                class="flex bg-yellow-500 text-black space-x-4 w-fit ml-3 text-sm"
              >
                Game is Pending
              </div>
            </div>
          </div>
          <p>Tickets</p>
          <div
            class="w-full lg:h-full h-400 md:h-250 overflow-y-auto"
            v-if="Ticket.gameId"
          >
            <div
              class="lg:grid md:grid md:grid-cols-2 space-x-3 lg:grid-cols-2 lg:gap-2 space-y-3 lg:space-x-0"
            >
              <div
                class="bg-darkbg p-2"
                v-for="(i, index) in returnGames"
                :key="index"
              >
                <div class="flex justify-start space-x-2">
                  <p>Spots</p>
                  <div
                    class=""
                    v-for="(j, index) in i.selectedNumbers"
                    :key="index"
                  >
                    <div>
                      <div v-if="DrawnNumber.numbers_That_Won">
                        <p
                          :class="{
                            'text-green-500':
                              DrawnNumber.numbers_That_Won.includes(j.number),
                          }"
                        >
                          {{ j.number }},
                        </p>
                      </div>

                      <p v-if="!DrawnNumber.numbers_That_Won">
                        {{ j.number }},
                      </p>
                    </div>
                  </div>
                </div>
                <br class="lg:hidden md:hidden" />
                <div class="md:text-base text-sm lg:flex justify-between">
                  <div>Price: {{ i.placedMoney }} birr</div>
                  <div>
                    Payout:
                    {{ i.possiblePayout }}
                  </div>
                  <div class="flex space-x-2">
                    <p>Prize Money:</p>
                    <p
                      class="text-blue-500"
                      v-if="Ticket.gameId.status == 'finished'"
                      :class="{ 'text-red-500': i.prizeMoney > 0 }"
                    >
                      {{ i.prizeMoney }} birr
                    </p>
                    <!-- <p
                    class="text-blue-500"
                    v-if="Ticket.gameId.status !== 'pending'"
                    :class="{ 'text-green-500': i.prizeMoney == 0 }"
                  >
                    {{ i.prizeMoney }}
                  </p> -->
                    <p
                      class="bg-yellow-500 text-black"
                      v-if="Ticket.gameId.status == 'pending'"
                    >
                      Pending
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full" v-if="!Ticket.gameId">
            <p>Tickets</p>
            <div class="grid grid-cols-2 gap-3 md:grid-cols-2">
              <div
                class="bg-darkbg p-2"
                v-for="(i, index) in returnGames"
                :key="index"
              >
                <div class="flex justify-start space-x-2">
                  <p>Spots</p>
                  <div
                    class=""
                    v-for="(j, index) in i.selectedNumbers"
                    :key="index"
                  >
                    <div>
                      <p>{{ j.number }},</p>
                    </div>
                  </div>
                </div>
                <div class="flex justify-between">
                  <div>Price: {{ i.placedMoney }} birr</div>
                  <div>
                    Possible Payout:
                    {{ i.possiblePayout }} birr
                  </div>
                  <div class="flex space-x-2">
                    <p>Prize Money:</p>
                    <p
                      :class="{
                        'text-red-500': Number(i.prizeMoney > 0),
                        'text-blue-500': Number(i.prizeMoney == 0),
                      }"
                    >
                      {{ i.prizeMoney }} birr
                    </p>
                    <!-- <p
                    class="text-blue-500"
                    v-if="Ticket.gameId.status !== 'pending'"
                    :class="{ 'text-green-500': i.prizeMoney == 0 }"
                  >
                    {{ i.prizeMoney }}
                  </p> -->
                    <!-- <p
                    class="bg-yellow-500 text-black"
                    v-if="Ticket.gameId.status == 'pending'"
                  >
                    Pending
                  </p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import spinner2 from "@/components/spinner2.vue";
import NetworkIssue from "@/Error/NetworkIssue.vue";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    spinner2,
    NetworkIssue,
  },
  data() {
    return {
      DrawnNumber: [],
      noConnection: false,
      spinner: false,
      DrawnNumber: [],
      Ticket: [],
      game: [],
      gameRequestSent: false,
      Num: false,
    };
  },
  props: {
    // Numbers: {
    //   //   require: true,
    // },
    info: {
      required: true,
    },
  },
  created() {
    this.gameRequestSent = true;
    this.Ticket = this.info;

    // console.log("this.DrawnNumber: ", this.DrawnNumber);

    // console.log("this.Numbers: ", this.Numbers.data.doc);
  },
  mounted() {
    this.$store
      .dispatch("tickets/searchGameTicketRecall", this.Ticket.gameId.customId)
      .then((res) => {
        if (res) {
          this.gameRequestSent = false;
          this.DrawnNumber = res.data.doc;
        } else {
          this.gameRequestSent = false;
        }
      })
      .catch((error) => {
        this.gameRequestSent = false;
      });
  },
  computed: {
    ...mapGetters("modals", ["reportModalStatus"]),
    returnGames() {
      return this.Ticket.game;
    },
    returnSelectedSpots() {
      if (this.Ticket) {
        this.selectedSpotsList = [];
        this.Ticket.game.forEach((element) => {
          let num = element.selectedNumbers;
          num.forEach((el) => {
            let result = Number(el.number);
            this.selectedSpotsList.push(result);
          });
        });
      }
      return this.selectedSpotsList;
    },
    returnLuckyNumbers() {
      if (this.DrawnNumber.length > 0) {
        return this.Numbers.data.doc.numbers_That_Won;
      } else {
        this.Num = true;
      }
    },
  },
  methods: {
    modal() {
      this.$store.dispatch("modals/closeAll");
      // this.$parent.fetchTickets();
    },
  },
};
</script>

<style></style>
