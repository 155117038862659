import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
// import animatecss from "animate.css";
// Vue.use(animatecss);
import "./styles/input.css";
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import "@/styles/font-awesome-4.7.0/css/font-awesome.css";

import XLSX from "xlsx";

Vue.prototype.$XLSX = XLSX;

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
import VueCountdownTimer from "vuejs-countdown-timer";
Vue.use(VueCountdownTimer);

import { DatePicker } from "v-calendar";
Vue.component("DatePicker", DatePicker);

import date from "./filters/date";
Vue.filter("date", date);

//.......... Base...........//
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
const requireComponent = require.context(
  // The relative path of the components folder
  "./components/base",

  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});
//.......End_Base.........//
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
