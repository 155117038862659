var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Myfont"}},[_c('div',{staticClass:"w-full max-w-4xl"},[_c('div',{staticClass:"p-2"},[_c('div',[_c('div',{staticClass:"px-2 rounded-md flex justify-between space-x-4 m-1",class:{
            'bg-red-500': _vm.notification.type === 'error',
            'bg-green-500': _vm.notification.type === 'success',
            'bg-teal-500': _vm.notification.type === 'logout',
            'bg-gray-500': _vm.notification.type === 'status',
          }},[_c('div',{staticClass:"flex space-x-3"},[_c('div',{staticClass:"flex"},[_c('div',{},[(_vm.notification.type !== 'logout')?_c('p',{staticClass:"text-white text-base text-center py-3 items-center first-letter:uppercase px-4",class:{ 'text-white': _vm.notification.type === 'logout' }},[_vm._v(" "+_vm._s(_vm.notification.type)+" ")]):_vm._e(),(_vm.notification.type === 'logout')?_c('p',{staticClass:"text-white text-base text-center py-3 items-center first-letter:uppercase px-4",class:{ 'text-white': _vm.notification.type === 'logout' }},[_vm._v(" success ")]):_vm._e()]),_c('p',{staticClass:"border",class:{
                  'border-red-600': _vm.notification.type === 'error',
                  'border-green-600': _vm.notification.type === 'success',
                  'border-teal-600': _vm.notification.type === 'logout',
                  'border-gray-600': _vm.notification.type === 'status',
                }})]),_c('p',{staticClass:"text-white text-sm py-3",class:{ 'text-white': _vm.notification.type === 'logout' }},[_vm._v(" "+_vm._s(_vm.notification.message)+" ")])]),_c('div',{staticClass:"pl-4 flex justify-center"},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("../../assets/close.svg"),"alt":""},on:{"click":function($event){return _vm.removeNotification(_vm.notification)}}})])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }