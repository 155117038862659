<template>
  <div>
    <div class="w-full p-4 text-white print:hidden">
      <div class="flex justify-between mx-auto mb-5">
        <p class="text-gray-400">Report Details</p>
        <img
          @click="modalClose()"
          class="cursor-pointer"
          src="../../assets/icons/x.svg"
          alt=""
          srcset=""
        />
      </div>
      <div class="bg-darkbg p-5 text-base">
        <div class="text-center">Summery Report</div>
        <div class="p-8 gap-5 text-center mt-2 w-full">
          <div class="flex text-center w-full space-x-3"></div>
          <div class="flex text-left space-x-3">
            <div>Starting Balance: 0</div>
          </div>
          <div class="flex text-left space-x-3">
            <div>
              Total Placed Money: {{ report.unreportedTotalPlacedMoney }}
            </div>
          </div>
          <div class="flex text-left space-x-3">
            <div>Redeemed: {{ report.unreportedTotalPayoutMoney }}</div>
          </div>
          <div class="flex text-left space-x-3">
            <div v-if="report.unreportedTotalProfit > 0">
              End Balance:
              {{
                (
                  report.unreportedTotalPlacedMoney -
                  report.unreportedTotalPayoutMoney
                ).toLocaleString()
              }}
            </div>
            <div v-if="report.unreportedTotalProfit < 0">
              End Balance: Negative
              {{
                (
                  report.unreportedTotalPlacedMoney -
                  report.unreportedTotalPayoutMoney
                ).toLocaleString()
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div
          v-if="reportRequestSent"
          class="'w-full cursor-pointer flex justify-center text-base mt-5 py-2 bg-text"
        >
          <div><BaseSpin /></div>
        </div>
        <BaseButton
          v-if="!reportRequestSent"
          @click="PrintReport()"
          buttonClass="w-full bg-text p-2 text-center mt-2"
          ><p v-if="!reportRequestSent">Report</p>
        </BaseButton>
      </div>
      <!-- <div class="">
        <BaseButton
          @click="PrintReport()"
          buttonClass="w-full bg-text p-2 text-center mt-2"
          >Report</BaseButton
        >
      </div> -->
      <!-- {{ report }} -->
    </div>
    <div class="w-printerSize hidden m-0 p-0 print:block text-black bg-white">
      <div class="text-center flex justify-around">
        <div>
          <div class="flex justify-center">
            <img class="w-16 h-16" src="../../../public//logo.svg" alt="" />
          </div>
          <div class="text-lg">KenoPro</div>
        </div>

        <div class="mt-4">
          <!-- <p class="text-center">time: new Date().toLocaleString();</p> -->
          <p class="text-center">{{ report.agentId.agentName }}</p>
          <p class="text-center">{{ report.agentId.branchLocation }}</p>
        </div>
      </div>
      <div class="text-black text-center m-0 p-0">
        <div class="text-center">Summery Report</div>
        <p class="text-center">{{ report.employeeName }}</p>
        <div class="flex justify-center">
          <div class="r text-base p-0 text-center mt-2 w-full">
            <div class="flex text-center w-full space-x-3"></div>
            <div class="ml-24 text-left space-x-3">
              <div>Starting Balance: 0</div>
            </div>
            <div class="ml-24 text-left space-x-3">
              <div>
                Total Placed Money: {{ report.unreportedTotalPlacedMoney }}
              </div>
            </div>
            <div class="ml-24 text-left space-x-3">
              <div>Redeemed: {{ report.unreportedTotalPayoutMoney }}</div>
            </div>
            <div class="ml-24 text-left space-x-3">
              <div v-if="report.unreportedTotalProfit > 0">
                End Balance:
                {{
                  (
                    report.unreportedTotalPlacedMoney -
                    report.unreportedTotalPayoutMoney
                  ).toLocaleString()
                }}
              </div>
              <div v-if="report.unreportedTotalProfit < 0">
                End Balance: Negative
                {{
                  (
                    report.unreportedTotalPlacedMoney -
                    report.unreportedTotalPayoutMoney
                  ).toLocaleString()
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- {{ report }} -->
    </div>
  </div>
</template>

<script>
import spinner2 from "@/components/spinner2.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      report: [],
      reportRequestSent: false,
      spinner2,
    };
  },
  props: {
    dataInfo: {
      required: true,
    },
  },
  created() {
    this.report = this.dataInfo;
    // console.log("this :", this.report);
  },
  computed: {
    returnRealTime() {},
  },
  methods: {
    modalClose() {
      this.$parent.Modal();
    },
    PrintReport() {
      this.reportRequestSent = true;
      this.$store
        .dispatch("branchEmployees/closeTodaysReport", this.report.id)
        .then((response) => {
          if (response) {
            this.reportRequestSent = false;
            // console.log('status" ', response);
            this.printPage();
            this.modalClose();
          }
        });
    },
    modalClose() {
      this.$store.dispatch("modals/modal", {
        stat: "reportModal",
        status: false,
      });
    },
    printPage() {
      const timestamp = new Date().toLocaleString();
      window.print();
    },
  },
};
</script>

<style></style>
