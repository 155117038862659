<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <select
      :class="selectClass"
      :value="value"
      @input="updateValue"
      v-bind="$attrs"
    >
      <input type="text" />
      <option
        v-for="option in options"
        :key="option"
        :selected="option === value"
      >
        <p class="p-2">{{ option }}</p>
      </option>
    </select>
  </div>
</template>

<script>
import { mixin } from "../../mixins/mixins";
export default {
  mixins: [mixin],
  props: {
    options: {
      type: Array,
      Object,
      require: true,
    },
    selectClass: {
      type: String,
    },
    optionClass: {
      type: String,
    },
  },
};
</script>

<style scoped>
/* .select {
  width: 250px;
} */
</style>
